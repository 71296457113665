/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientData } from '../models/ClientData';
import type { PreciseFpConfigBase } from '../models/PreciseFpConfigBase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PrecisefpService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Config
     * @returns PreciseFpConfigBase Successful Response
     * @throws ApiError
     */
    public getPreciseFpConfig(): CancelablePromise<PreciseFpConfigBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/precisefp/config',
        });
    }

    /**
     * Post Profile
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public postPrecisePfProfile(
        requestBody: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/precisefp/profile',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Post Person
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public postPrecisePfPerson(
        requestBody: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/precisefp/person',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Client Data
     * @param eventId
     * @returns ClientData Successful Response
     * @throws ApiError
     */
    public getClientData(
        eventId: string,
    ): CancelablePromise<ClientData> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/precisefp/client_data',
            query: {
                'event_id': eventId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Client Data
     * @param eventId
     * @param requestBody
     * @returns ClientData Successful Response
     * @throws ApiError
     */
    public updateClientData(
        eventId: string,
        requestBody: ClientData,
    ): CancelablePromise<ClientData> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/precisefp/client_data',
            query: {
                'event_id': eventId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
