import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getZoomSettings } from 'service/integrations/zoomApi'

export default function ZoomIntegrationLine() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: zoom, isLoading } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={() => navigate(Path.ZoomSettings)}>
        <Box
          component="img"
          sx={sxLogo}
          src={Media.zoomLogo}
        />
        {
          isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={50} />
            : (
              zoom?.isVideoReady ? (
                <Box>
                  <Typography>Zoom Video</Typography>
                  {zoom.profile?.email
                    ? <Typography>{`Signed in as ${zoom.profile?.email}`}</Typography>
                    : <Typography>Connected</Typography>
                  }
                </Box>
              ) : (
                <Typography>Zoom Video</Typography>
              )
            )
        }
        <ChevronRightIcon />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}