import React, { useEffect, useState } from 'react'
import { Card, Typography, SxProps, TextField, Snackbar, Alert, LinearProgress, Box, CardHeader, CardContent, Switch, Skeleton } from '@mui/material'
import { getUserConfig, getUserConfigBotName, setWebRecorderAutoDownload, updateBotName } from '../../service'
import { logInfo } from '../../log'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'

export default function WebRecorderSettings() {
  const queryClient = useQueryClient()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [snackSuccess, setSnackSuccess] = useState(false)

  const { data, isLoading } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })

  async function onAutoDownload(checked: boolean) {
    setErr(undefined)
    setBusy(true)
    const res = await setWebRecorderAutoDownload(checked)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <>
      <Box sx={gSx.Row}>
        {
          isLoading
            ? <Skeleton variant="rectangular" width={80} height={50} />
            : <Switch
              checked={data?.web_recorder_auto_download}
              onChange={(event, checked) => onAutoDownload(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={busy}
            />
        }

        <Box>
          <Typography variant='h6'>Auto Download To Your Computer</Typography>
          <Typography>After Web Recorder finishes, have FinMate immediately download a copy to your computer.</Typography>
        </Box>
      </Box>

      {busy && <LinearProgress />}
      {err && <Typography color={'tomato'} >{err}</Typography>}

      <Snackbar
        open={snackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          FinMate Auto Download Setting Updated
        </Alert>
      </Snackbar>
    </>
  )
}
