import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'

import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
} from 'material-react-table'
import { PersonalDetails } from '../../client'

interface Props {
  eventId?: string
  personalDetailsList?: PersonalDetails[]
  onUpdate: (updated: PersonalDetails[]) => (void)
}

export default function PersonalDetailsTable({ eventId, personalDetailsList, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails[]>([])

  useEffect(() => {
    if (personalDetailsList) {
      setPersonalDetails(personalDetailsList)
    }
  }, [personalDetailsList])

  const columns = useMemo<MRT_ColumnDef<PersonalDetails>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        muiEditTextFieldProps: ({ row }) => ({
          required: true,
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['First Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        muiEditTextFieldProps: ({ row }) => ({
          required: true,
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['Last Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
        editVariant: 'select',
        editSelectOptions: ["M", "F"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['Gender'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 30
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate', // TODO: format check
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['Birthdate'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Marital Status',
        header: 'Marital Status',
        editVariant: 'select',
        editSelectOptions: ["Single", "Married", "Divorced", "Widowed", "Domestic Partner"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['Marital Status'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Citizenship',
        header: 'Citizenship',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            const rowIdx = Number(row.id)
            const updated = [...personalDetails]
            updated[rowIdx]['Citizenship'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [personalDetails],
  )

  const table = useMaterialReactTable({
    columns,
    data: personalDetails,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
  })

  return (

    <MRT_TableContainer table={table} />

  )
}
