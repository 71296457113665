import React from 'react'
import { Typography, Box, Card, SxProps, Button, Skeleton, ButtonBase } from '@mui/material'
import 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Media } from 'service/media'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { getZoomSettings } from 'service/integrations/zoomApi'
import { config } from 'service/api'

export default function ZoomPhoneIntegrationLine() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { data: zoom, isLoading } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })

  if (config.env == 'prod')
    return // hide from prod, zoom review can take a while

  return (
    <Card>
      <ButtonBase sx={sxContainer} onClick={() => navigate(Path.ZoomSettings)}>
        <Box component="img" sx={sxLogo}
          src={Media.zoomPhone}
        />

        <Box>
          <Typography>Zoom Phone</Typography>

          {isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={20} />
            : zoom?.hasApiAccess
              ? zoom.isPhoneReady
                ? zoom.profile?.email
                  ? <Typography>{`Signed In as ${zoom.profile?.email}`}</Typography>
                  : <Typography>Connected</Typography>
                : <Typography color={'darkred'}>Requires Zoom Phone Plan</Typography>
              : zoom?.isVideoReady
                ? <Typography color={'darkred'}>Requires Re-SignIn</Typography>
                : <Box />
          }
        </Box>
        <ChevronRightIcon />
      </ButtonBase>
    </Card>
  )
}

const sxLogo: SxProps = {
  width: 38,
}

const sxContainer: SxProps = {
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between',
}