import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { BankAccount } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'

interface Props {
  eventId?: string
  bankAccountListInput?: BankAccount[]
  onUpdate: (updated: BankAccount[]) => (void)
}

export default function BankAccountsTable({ eventId, bankAccountListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [bankAccountList, setBankAccountList] = useState<BankAccount[]>([])
  const [newBankAccount, setNewBankAccount] = useState<BankAccount>()

  useEffect(() => {
    if (bankAccountListInput) {
      console.log("bankAccountList", bankAccountListInput)
      setBankAccountList(bankAccountListInput)
    }
  }, [bankAccountListInput])

  const columns = useMemo<MRT_ColumnDef<BankAccount>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Name: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 130
      },
      {
        accessorKey: 'Primary',
        header: 'Primary',
        editSelectOptions: ["Yes", "No"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Primary: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Primary'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Type: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Type'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                Owner: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.id)
            const updated = [...bankAccountList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Average Balance',
        header: 'Average Balance',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Average Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Average Balance'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Bank Name',
        header: 'Bank Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Bank Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Bank Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Bank Address',
        header: 'Bank Address',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewBankAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Bank Address': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...bankAccountList]
            updated[rowIdx]['Bank Address'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [bankAccountList, newBankAccount],
  )

  const handleCreate: MRT_TableOptions<BankAccount>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...bankAccountList]
    if (newBankAccount) {
      updated.push(newBankAccount)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<BankAccount>) {
    const updated = [...bankAccountList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: bankAccountList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewBankAccount({}),
    onCreatingRowSave: handleCreate,
    // renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
    //   <>
    //     <DialogTitle variant="h3">Add New Bank Account</DialogTitle>
    //     <DialogContent
    //       sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
    //     >
    //       {internalEditComponents} {/* or render custom edit components here */}
    //     </DialogContent>
    //     <DialogActions>
    //       <MRT_EditActionButtons variant="text" table={table} row={row} />
    //     </DialogActions>
    //   </>
    // ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="Bank Accounts"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}
