import React, { useEffect, useState } from 'react'
import {
  ButtonGroup,
  Button,
  Tooltip,
  Box,
  Popover,
  SxProps,
} from '@mui/material'
import { Editor } from '@tiptap/react'
import AddIcon from '@mui/icons-material/Add'
import NotesIcon from '@mui/icons-material/Notes'
import ChecklistIcon from '@mui/icons-material/Checklist'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getEventSummaryBase, getEventTasks } from 'service/eventApi'
import { convertTasksToHtml } from 'utils/eventAnalysis'

interface MenuProps {
  editor?: Editor | null
  eventId: string
}

export const EditorAddContent = ({ editor, eventId }: MenuProps) => {
  const queryClient = useQueryClient()
  const [addPopover, setAddPopover] = useState<HTMLButtonElement | null>(null)

  const { data: summary } = useQuery({
    queryKey: ['getEventSummary', eventId],
    queryFn: async () => await getEventSummaryBase(eventId),
    enabled: queryClient.getQueryData(['getEventSummary', eventId]) === undefined,
  })

  const { data: tasks } = useQuery({
    queryKey: ['getEventTasks', eventId],
    queryFn: async () => await getEventTasks(eventId),
    enabled: queryClient.getQueryData(['getEventTasks', eventId]) === undefined,
  })

  function addSummary() {
    if (editor) {
      editor.chain().focus()
        .insertContent('\n' + summary?.details_html_str) // Appends the new content
        .run() // Executes the commands
    }
    setAddPopover(null)
  }

  function addTasks() {
    if (editor && tasks?.items_edited) {
      const tasksHtml = convertTasksToHtml(tasks?.items_edited)
      editor.chain().focus()
        .insertContent('\n' + tasksHtml) // Appends the new content
        .run()
    }
    setAddPopover(null)
  }

  if (!editor || (!tasks && !summary))
    return null

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>

        <ButtonGroup sx={{ paddingX: '5px' }} size="small" aria-label="small button group">
          <Tooltip title="Add Content">
            <Button onClick={(e) => setAddPopover(e.currentTarget)}>
              <AddIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>

      </Box>

      <Popover
        open={Boolean(addPopover)}
        anchorEl={addPopover}
        onClose={() => setAddPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={sxAdd}>
          {summary &&
            <Button startIcon={<NotesIcon />} onClick={addSummary}>
              Add Summary
            </Button>
          }
          {tasks &&
            <Button startIcon={<ChecklistIcon />} onClick={addTasks}>
              Add Tasks
            </Button>
          }

        </Box>
      </Popover >
    </Box >
  )
}

const sxAdd: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  padding: 1
}
