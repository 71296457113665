import React from 'react'
import { Box } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import CampaignIcon from '@mui/icons-material/Campaign'
import CircleIcon from '@mui/icons-material/Circle'
import { gColor } from 'styles/Theme'

export const SummaryRerunIcon = () => {
  return (
    <Box position="relative" display="inline-block" sx={{ marginRight: "15px" }}>
      <ReplayIcon sx={{ color: 'gray' }} />
      <TextSnippetIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: 16,
          color: 'gray',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}
export const EmailRerunIcon = () => {
  return (
    <Box position="relative" display="inline-block" sx={{ marginRight: "15px" }}>
      <ReplayIcon sx={{ color: 'gray' }} />
      <ContactMailIcon
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          fontSize: 16,
          color: 'gray',
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}

export const WhatsNewRecent = () => {
  return (
    <Box position="relative" display="inline-block" sx={{ marginRight: "15px" }}>
      <CampaignIcon />
      <CircleIcon
        sx={{
          position: 'absolute',
          top: '-20%',
          left: '60%',
          fontSize: 15,
          color: gColor.green500,
          backgroundColor: 'background.paper',
          borderRadius: '50%',
        }}
      />
    </Box>
  )
}
