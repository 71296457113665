import { Card, SxProps } from '@mui/material'
import { grey } from '@mui/material/colors'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode;
  sx?: SxProps;
}

const sxMain = {
  border: '1px solid',
  borderColor: grey[300],
  borderRadius: '8px',
  boxShadow: 'none',
  padding: '16px',
}

export default function SubCard({ children, sx }: Props) {

  if (sx)
    return (
      <Card sx={{ ...sxMain, ...sx }}>
        {children}
      </Card >
    )

  return (
    <Card sx={sxMain}>
      {children}
    </Card>
  )
}