import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Card, Container, Typography, IconButton, Divider, CardHeader } from "@mui/material"
import { logInfo } from 'log'
import PricingPlansNew from '../../components/pay/PricingPlansNew'
import StripeCustomerInfo from '../../components/pay/StripeCustomerInfo'
import { getOrg, isAdminRole } from 'service/orgsApi'
import { getSubscription } from 'service/payApi'
import Grid from '@mui/material/Unstable_Grid2'
import ActiveOrgSeats from 'components/pay/ActiveOrgSeats'
import SeatsPurchased from 'components/pay/SeatsPurchased'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import EditSubscriptionDialog from '../../components/pay/EditSubscriptionDialog'
import { gSx } from 'styles/Theme'
import Discount from 'components/pay/Discount'

export default function BillingScreen() {
  const queryClient = useQueryClient()
  const [showEditDialog, setShowEditDialog] = useState(false)

  useEffect(() => {
    logInfo('Billing Tab')
  }, [])

  const { data: org, isLoading: isLoadingOrg } = useQuery(['getOrg'], getOrg)
  const { data: sub, isLoading: isLoadSub } = useQuery(['getSubscription'], getSubscription)
  const isLoading = isLoadingOrg || isLoadSub
  const isAdmin = isAdminRole(org)
  const isOrgSubscribed = !!sub?.subscription

  async function onEditClose(refresh?: boolean) {
    if (refresh) {
      // update all
      queryClient.invalidateQueries(['getOrg'])
      queryClient.invalidateQueries(['getSubscription'])
      queryClient.invalidateQueries(['getSubInfo'])
    }
    setShowEditDialog(false)
  }

  return (
    <Container>
      <Box sx={gSx.RowBetween}>
        <Typography variant='h1' sx={{ paddingY: 2 }} >Billing</Typography>

        {isOrgSubscribed && isAdmin &&
          <IconButton onClick={() => setShowEditDialog(true)}>
            <MoreHorizIcon />
          </IconButton>
        }
      </Box>

      <EditSubscriptionDialog
        open={showEditDialog}
        onClose={onEditClose}
      />

      <Grid container spacing={2}>
        <Grid xs={12} md={4} sx={{ display: 'flex' }}>
          <Card sx={{ margin: 0, flexGrow: 1, paddingBottom: 0 }}>
            <CardHeader title='Billing Information' />

            <StripeCustomerInfo org={org} />
            <Box sx={{ padding: 1 }} />
            <Discount />
          </Card>
        </Grid>

        <Grid xs={12} md={4} sx={{ display: 'flex' }}>
          <Card sx={{ margin: 0, flexGrow: 1 }}>
            <SeatsPurchased sub={sub} isLoading={isLoading} />
          </Card>
        </Grid>

        <Grid xs={12} md={4} sx={{ display: 'flex' }}>
          <Card sx={{ margin: 0, flexGrow: 1 }}>
            <ActiveOrgSeats org={org} isLoading={isLoading} />
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ padding: 1 }} />

      <PricingPlansNew sub={sub} org={org} isAdmin={isAdmin} />

    </Container>
  )
}
