import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider, Container, CardHeader } from '@mui/material'
import 'firebase/auth'
import { useQuery } from '@tanstack/react-query'
import CloseIcon from '@mui/icons-material/Close'
import { gSx } from '../../styles/Theme'
import { disconnectWealthbox, getWealthboxProfile, wealthboxOAuth } from 'service/integrations/wealthboxApi'
import { Media } from 'service/media'
import { logInfo } from 'log'
import { LoadingButton } from '@mui/lab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Path } from 'routes'
import WealthboxImportContacts from 'components/integrations/wealthbox/WealthboxImportContacts'
import { useNavigate } from 'react-router-dom'
import WealthboxConfigCard from 'components/integrations/wealthbox/WealthboxConfigCard'
import ContainerSmall from 'components/ContainerSmall'
import ScrollToAnchor from 'components/ScrollToAnchor'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'

export default function WealthboxSettings() {
  const navigate = useNavigate()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    logInfo('Wealthbox Settings Screen')
  }, [])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['getWealthboxProfile'], queryFn: getWealthboxProfile,
  })
  const isSignedIn = !!data?.id

  async function onSignIn() {
    setBusy(true)
    logInfo('Wealthbox Sign In Pressed')
    await wealthboxOAuth()
    setBusy(false)
  }

  async function signout() {
    logInfo('Wealthbox Signout Pressed')
    setErr(undefined)
    setBusy(true)
    const res = await disconnectWealthbox()
    if (res instanceof Error)
      setErr(res.message)
    else
      await refetch()
    setBusy(false)
  }

  return (
    <ContainerSmall>
      <Button
        onClick={() => navigate(Path.Integrations)}
        startIcon={<ArrowBackIcon />}
      >
        Integrations
      </Button>

      <Typography variant='h3'>Wealthbox Integration</Typography>
      <Divider sx={{ marginY: '2vh ' }} />

      <Link href="https://wealthbox.com" rel='noopener' target='_blank'>
        <Box
          component="img"
          sx={icon}
          src={Media.wealthbox}
          alt="Wealthbox"
        />
      </Link>

      <Box sx={{ padding: '1vh ' }} />

      <Typography>Connect FinMate AI to Wealthbox CRM, to easily keep your records up to date. </Typography>
      <Box sx={{ padding: '1vh ' }} />
      <Typography>Transfer your AI generated meeting notes, tasks, and contacts to Wealthbox.</Typography>

      <Box sx={{ padding: '1vh ' }} />

      <Card>

        {isLoading ? (
          <Skeleton variant="rectangular" width={'100%'} height={100} />
        ) : (
          data ? (
            <Box sx={gSx.RowBetween}>
              <Typography>Signed In as {data.name}, {data.email}</Typography>

              <Tooltip title="Disconnect Wealthbox">
                <LoadingButton
                  aria-label='Disconnect Wealthbox'
                  onClick={signout}
                  disabled={busy}
                  loading={busy}
                >
                  <CloseIcon />
                </LoadingButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={sxConnect}>
              <LoadingButton
                variant='contained' onClick={onSignIn}
                loading={busy}
                disabled={busy}
                sx={{ width: 150 }}
              >
                Connect
              </LoadingButton>
            </Box>
          )
        )
        }
        {err && <Typography sx={{ color: 'tomato' }}>
          {err}
        </Typography>
        }
      </Card >

      {isSignedIn && (
        <>
          <WealthboxConfigCard />
          <WealthboxImportContacts />

          <Card>
            <CardHeader title='Import Users/Teammates' />

            <Typography>Teammates are automatically imported from Wealthbox Teams.</Typography>

            <Typography>
              Learn how to create Wealthbox Teams&nbsp;
              <Link rel='noopener' target='_blank' href={'https://www.wealthbox.com/how-to/how-to-create-teams'}>
                here
              </Link>
              .
            </Typography>

            <Box sx={{ padding: 1 }} />
            <Typography>You can assign tasks to teammates.</Typography>

          </Card>

        </>
      )}

      <Button target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/category/wealthbox"
        startIcon={<TextSnippetIcon />}
      >
        Wealthbox Docs
      </Button>

      <ScrollToAnchor />
    </ContainerSmall >
  )
}

const icon: SxProps = {
  width: 200,
  marginRight: 2
}

const sxConnect: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}