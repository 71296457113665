import { ActionItem, EventBase, EventUsage, SentimentDoc, TranscriptEntryBase } from '../client'

interface Graph {
  x: number
  y: number
}

interface SpeakerIdNameMap {
  [id: string]: string // key=id, value=name
}

interface SpeakTotal {
  [speaker: string]: SpeakTotalData
}

interface SpeakTotalData {
  duration: number
  summary?: string // ie. 1 min 43 sec (56%)
}

interface SpeakerTimeline {
  [speaker: string]: Graph[]
}

interface Monologues {
  [speaker: string]: number // seconds
}

interface SentimentData {
  score: Graph[]
  magnitude: Graph[]
}

interface SentimentTimeline {
  [speaker: string]: SentimentData
}

export function getSpeakingTimelines(transcript?: TranscriptEntryBase[]): SpeakerTimeline {
  const speakerTimeline: SpeakerTimeline = {}

  if (!transcript) return speakerTimeline

  transcript.forEach((item, index) => {
    const speaker = item.speaker ?? item.speaker_id ?? `${index}`
    const start = item.start_timestamp ?? 0
    const end = item.end_timestamp ?? 0

    if (!speakerTimeline[speaker]) { // initialize
      speakerTimeline[speaker] = [
        { x: 0, y: 0 }
      ]
      speakerTimeline[speaker].push({ x: start, y: 0 })
      speakerTimeline[speaker].push({ x: start, y: 1 })
      speakerTimeline[speaker].push({ x: end, y: 1 })
      speakerTimeline[speaker].push({ x: end, y: 0 })
    }
    speakerTimeline[speaker].push({ x: start, y: 0 })
    speakerTimeline[speaker].push({ x: start, y: 1 })
    speakerTimeline[speaker].push({ x: end, y: 1 })
    speakerTimeline[speaker].push({ x: end, y: 0 })
  })
  return speakerTimeline
}

export function getSpeakerTotalTalk(transcript?: TranscriptEntryBase[]): SpeakTotal {
  const total: SpeakTotal = {}
  if (!transcript) return total

  let totalDuration = 0
  transcript.forEach((item, index) => {
    const speaker = item.speaker ?? item.speaker_id ?? `${index}`
    const start = item.start_timestamp ?? 0
    const end = item.end_timestamp ?? 0
    const duration = end - start
    totalDuration += duration

    if (!total[speaker]) {  // initialize
      total[speaker] = { duration }
    } else {
      total[speaker].duration += duration
    }
  })
  Object.entries(total).map(([speaker, data]) => {
    const percentSpoke = ((100 * data.duration) / totalDuration).toFixed(1)
    const seconds = data.duration
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    total[speaker].summary = `${minutes}min ${remainingSeconds.toFixed(0)}sec  (${percentSpoke}%)`
  })
  return total
}

export function getLongestMonologues(transcript?: TranscriptEntryBase[]) {

  const monologues: Monologues = {}

  if (!transcript)
    return monologues

  transcript.forEach((item, index) => {
    const speaker = item.speaker ?? item.speaker_id ?? `${index}`

    if (!monologues[speaker]) // initialize
      monologues[speaker] = 0.01 // cannot be zero.

    const start = item.start_timestamp ?? 0
    const end = item.end_timestamp ?? 0
    const duration = end - start
    monologues[speaker] = Math.max(monologues[speaker], duration)
  })
  return monologues
}

export function secondsToMinSec(sec: number) {
  const minutes = Math.floor(sec / 60)
  const remainingSeconds = sec % 60
  if (minutes === 0) {
    return `${remainingSeconds.toFixed(0)} Seconds`
  } else if (minutes === 1) {
    return `${minutes.toFixed(0)} Minute ${remainingSeconds.toFixed(0)} Seconds`
  }
  return `${minutes.toFixed(0)} Minutes ${remainingSeconds.toFixed(0)} Seconds`
}

export function getSpeakerSentiment(data?: SentimentDoc, speakerMap?: SpeakerIdNameMap) {
  const timeline: SentimentTimeline = {}
  if (!data) return timeline
  data.result?.forEach((item, index) => {
    // users could run this prior to changing names
    const speaker = (speakerMap && speakerMap[item.speaker_id ?? '']) ?? item.speaker_id ?? `${index}`
    const start = item.start_timestamp ?? 0
    const end = item.end_timestamp ?? 0

    if (!timeline[speaker]) { // initialize
      timeline[speaker] = {
        score: [{ x: 0, y: 0 }],
        magnitude: [{ x: 0, y: 0 }]
      }
    }
    timeline[speaker].score.push({ x: start, y: 0 })
    timeline[speaker].score.push({ x: start, y: item.score ?? 0 })
    timeline[speaker].score.push({ x: end, y: item.score ?? 0 })
    timeline[speaker].score.push({ x: end, y: 0 })

    timeline[speaker].magnitude.push({ x: start, y: 0 })
    timeline[speaker].magnitude.push({ x: start, y: item.magnitude ?? 0 })
    timeline[speaker].magnitude.push({ x: end, y: item.magnitude ?? 0 })
    timeline[speaker].magnitude.push({ x: end, y: 0 })
  })
  return timeline
}

export function getSpeakerIdNameMap(entries?: TranscriptEntryBase[]) {
  if (!entries)
    return
  const map: SpeakerIdNameMap = {}

  entries.forEach(((item, index) => {
    const key = item.speaker_id ?? `${index}`
    if (!map[key]) {
      map[key] = item.speaker ?? item.speaker_id ?? `${index}`
    }
  }))
  return map
}

export interface GraphDate {
  x?: number
  xLabel: Date
  y: number
}

export function getUsageHistogram(pastNumMonths: number, events?: EventUsage[]) {
  if (!events) {
    return []
  }
  const combineMonthly: { [key: string]: GraphDate } = {}
  events.forEach((event) => {

    const date = new Date(event.start_time || 0)
    const dateWithoutDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const botHours = (event.bot_minutes_used || 0) / 60

    const key = dateWithoutDay.getTime() // Using ISO string as the key
    if (combineMonthly[key]) {
      combineMonthly[key].y += botHours
    } else {
      combineMonthly[key] = { xLabel: dateWithoutDay, y: botHours }
    }
  })

  // last 6 months, fill in zero months
  const today = new Date()
  const res: GraphDate[] = []

  for (let i = 0; i < pastNumMonths; i++) {
    const month = new Date(today.getFullYear(), today.getMonth() - i, 1)
    const combinedMatchMonth = combineMonthly[month.getTime()]

    const item: GraphDate = { x: pastNumMonths - i, xLabel: month, y: 0 }
    if (combinedMatchMonth)
      item.y = combinedMatchMonth.y
    res.unshift(item)
  }
  return res
}

export function convertTasksToHtml(tasks: ActionItem[]) {
  let html = "<h3>Tasks</h3>"
  if (tasks.length > 0) {
    html += "<ul>"
    tasks.forEach(item => {
      html += "<li>" + item.description

      html += " ("
      if (item.assignee && item.assignee.length > 0) {
        html += "Assignee: " + item.assignee
      }
      if (item.assignee && item.assignee.length > 0 && item.due_date_datetime)
        html += ", "
      if (item.due_date_datetime) {
        const due = new Date(item.due_date_datetime)
        html += "Due date: " + due.toLocaleDateString()
      }
      html += ")"

      html += "</li>"
    })
    html += "</ul>"
  }
  return html
}