import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { MessagePayload } from 'firebase/messaging'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getNotifications, onMessageListener, readAllNotifications } from 'service/msgApi'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Alert, AlertColor, AlertTitle, Badge, Box, Button, ButtonBase, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Snackbar, SxProps, Typography } from '@mui/material'
import { Path } from '../../routes'
import { Api } from '../../service/api'
import { logErr, logInfo } from '../../log'
import { NotificationBase, NotificationSeverity } from '../../client'
import { sxSideNavListItemButton } from './SideNavItem'

dayjs.extend(relativeTime)

export default function SideNavUpdate() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const buttonRef = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const isPublic = window.location.href.includes(Path.PublicEvent)
  const popoverId = popoverOpen ? 'update-popover' : undefined

  const [unreadCount, setUnreadCount] = useState(0)
  const updatePopoverClick = async () => {
    if (isPublic) {
      navigate(Path.SignUp)
      return
    }
    setPopoverOpen(true)
    await readAllNotifications()
    setUnreadCount(0)
  }

  const { data: notifications, refetch: refetchNotifications } = useQuery({
    queryKey: ['getNotifications'],
    queryFn: getNotifications
  })

  const onClearAllNotifications = async () => {
    try {
      const api = await Api()
      const res = await api.communications.clearAllNotifications()
    } catch (e) {
      logErr('clearAllNotifications', { e })
    }
    refetchNotifications()
    setPopoverOpen(false)
  }

  useEffect(() => {
    const unreadCount = notifications?.filter(item => !item.read).length ?? 0
    setUnreadCount(unreadCount)
  }, [notifications])

  const [currentnotif, setCurrentNotif] = useState({ title: '', body: '', severity: "info" as AlertColor })
  const [openNotification, setOpenNotification] = React.useState(false)
  onMessageListener()
    .then((payload: MessagePayload) => {
      const title = payload?.notification?.title ? payload?.notification?.title : ""
      const body = payload?.notification?.body ? payload?.notification?.body : ""
      const severity = (payload?.data?.severity ? payload?.data?.severity : "info") as AlertColor
      setCurrentNotif({ title: title, body: body, severity: severity })
      refetchNotifications()
      if (payload?.data?.event_id) {
        queryClient.invalidateQueries(['getEventSummaryStatus', payload?.data?.event_id])
        queryClient.invalidateQueries(['getEventSummary', payload?.data?.event_id])
        queryClient.invalidateQueries(['getEvent', payload.data.event_id])

        if (window.location.href.includes(Path.Home)) {
          queryClient.invalidateQueries(['getEvents'])
        }
      }

    })
    .catch((err) => console.log('failed: ', err))

  useEffect(() => {
    if (currentnotif?.title) {
      setOpenNotification(true)
    }
  }, [currentnotif])

  function handleNotificationClose() {
    setOpenNotification(false)
    setCurrentNotif({ title: "", body: "", severity: "info" as AlertColor })
  }

  function onClickNotificationItem(notification: NotificationBase) {
    console.log("onClickNotificationItem", notification)
    if (notification.severity == NotificationSeverity.ERROR) {
      // when error, most likely the event is already deleted
      return
    }
    setPopoverOpen(false)
    navigate(`${Path.Event}/${notification.event_id}`)
  }

  return (
    <Box sx={{ paddingY: '1px' }}>
      <ListItemButton ref={buttonRef}
        sx={sxSideNavListItemButton}
        onClick={() => { updatePopoverClick() }}
      >
        <ListItemIcon sx={{ color: 'lightslategrey', minWidth: '38px' }} >
          <Badge badgeContent={unreadCount} color="primary">
            <NotificationsNoneIcon color="action" />
          </Badge>
        </ListItemIcon>
        <ListItemText primary='Updates' />
      </ListItemButton>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={buttonRef?.current}
        onClose={() => setPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ maxHeight: '600px' }}
      >
        <Box style={{ width: '600px', maxWidth: '700px', backgroundColor: 'white' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Typography sx={{ p: 1 }} variant="h4">Updates</Typography>
            <Button onClick={onClearAllNotifications} disabled={notifications?.length == 0}>Clear All</Button>
          </Box>
          <Divider />
          <List>
            {notifications?.length == 0 ? (
              <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '300px' }}>
                <NotificationsNoneIcon color='primary'></NotificationsNoneIcon>
                <Box sx={{ padding: '6px' }} />
                <Typography variant="h6" color='grey'>No Updates</Typography>
              </Box>) : (

              notifications?.map((notification: NotificationBase, index) => (
                <ListItem key={index}>
                  <ButtonBase key={index} onClick={() => onClickNotificationItem(notification)} style={{ width: '100%', textAlign: 'left' }}>
                    <Alert severity={notification.severity} sx={{ width: '100%' }} >
                      <AlertTitle>{notification.title}</AlertTitle>
                      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                        <Typography>{parse(notification?.body_html ?? notification?.body ?? "")}</Typography>
                        <Typography style={{ alignSelf: 'flex-end', fontStyle: 'italic', marginTop: 'auto', fontSize: '0.9rem' }}>{dayjs(notification.created_at).fromNow()} </Typography>
                      </Box>
                    </Alert>
                  </ButtonBase>
                </ListItem>
              ))
            )}
          </List>
        </Box>

      </Popover >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotification}
        autoHideDuration={currentnotif.severity == "error" ? null : 10000}
        onClose={handleNotificationClose}
      >
        <Alert
          variant="filled"
          onClose={handleNotificationClose}
          severity={currentnotif.severity}
          sx={{ width: '100%' }}
        >
          <AlertTitle>{currentnotif.title}</AlertTitle>
          {currentnotif.body}
        </Alert>
      </Snackbar>
    </Box >
  )
}
