import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { InvestmentAccount } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'

interface Props {
  eventId?: string
  investmentAccountListInput?: InvestmentAccount[]
  onUpdate: (updated: InvestmentAccount[]) => (void)
}

export default function InvestmentAccountsTable({ eventId, investmentAccountListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [investmentAccountList, setInvestmentAccountList] = useState<InvestmentAccount[]>([])
  const [newInvestmentAccount, setNewInvestmentAccount] = useState<InvestmentAccount>()

  useEffect(() => {
    if (investmentAccountListInput) {
      console.log("investmentAccountList", investmentAccountListInput)
      setInvestmentAccountList(investmentAccountListInput)
    }
  }, [investmentAccountListInput])

  const columns = useMemo<MRT_ColumnDef<InvestmentAccount>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewInvestmentAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...investmentAccountList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewInvestmentAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...investmentAccountList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      // {
      //   accessorKey: 'Type',
      //   header: 'Type',
      //   muiEditTextFieldProps: ({ row }) => ({
      //     onChange: (event) => {
      //       if (row.index < 0) {
      //         setNewInvestmentAccount((prevNewAccount) => ({
      //           ...prevNewAccount,
      //           'Type': event.target.value,
      //         }))
      //         return
      //       }
      //       const rowIdx = Number(row.index)
      //       const updated = [...investmentAccountList]
      //       updated[rowIdx]['Type'] = event.target.value
      //       onUpdate(updated)
      //     },
      //   }),
      //   size: 100
      // },

      {
        accessorKey: 'Cost Basis',
        header: 'Cost Basis',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewInvestmentAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Cost Basis': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...investmentAccountList]
            updated[rowIdx]['Cost Basis'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewInvestmentAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Current Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...investmentAccountList]
            updated[rowIdx]['Current Value'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gain',
        header: 'Gain',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewInvestmentAccount((prevNewAccount) => ({
                ...prevNewAccount,
                'Gain': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...investmentAccountList]
            updated[rowIdx]['Gain'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [investmentAccountList, newInvestmentAccount],
  )

  const handleCreate: MRT_TableOptions<InvestmentAccount>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...investmentAccountList]
    if (newInvestmentAccount) {
      updated.push(newInvestmentAccount)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<InvestmentAccount>) {
    const updated = [...investmentAccountList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: investmentAccountList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewInvestmentAccount({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New Taxable Investment Accounts</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="Taxable Investment Accounts"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}
