import React, { useEffect, useState } from "react"
import { FinmateSubscription, OrgBase, ProductName } from "../../client"
import { Skeleton, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { payingSeatsRemaining } from "../../service/orgsApi"
import { logInfo } from "log"

interface Props {
  org?: OrgBase
  sub?: FinmateSubscription
  isLoading: boolean
}

export default function StripeSubscriptionRemaining({ org, sub, isLoading }: Props) {

  const standardRemaining = payingSeatsRemaining(ProductName.STANDARD, org, sub)
  const starterRemaining = payingSeatsRemaining(ProductName.STARTER, org, sub)
  const liteRemaining = payingSeatsRemaining(ProductName.LITE, org, sub)

  useEffect(() => {
    logInfo('StripeSubscriptionRemaining', { standardRemaining, starterRemaining, liteRemaining })
  }, [])

  return (
    <Grid container alignItems="center">
      <Grid xs={6}>
        <Typography variant='h6' sx={{ paddingLeft: '12px' }}>Seats Remaining</Typography>
      </Grid>

      <Grid xs={4}>
        <Typography variant='body2'>
          Standard Plan
        </Typography>
        <Typography variant='body2'>
          Starter Plan
        </Typography>
        <Typography variant='body2'>
          Lite Plan
        </Typography>
      </Grid>

      <Grid xs={2}>
        {isLoading
          ? <Skeleton variant="rectangular" width={'100%'} height={20} />
          : (
            <>
              <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                {`${standardRemaining}`}
              </Typography>
              <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                {`${starterRemaining}`}
              </Typography>
              <Typography variant='body2' align="right" sx={{ paddingRight: 2 }}>
                {`${liteRemaining}`}
              </Typography>
            </>
          )
        }
      </Grid>
    </Grid>
  )
}
