import React, { useEffect, useState } from 'react'
import { auth, config } from '../../service/api'
import { Box, SxProps, Card, Typography, IconButton, CardHeader, Avatar, Button } from '@mui/material'
import { capitalizeEveryWord } from '../../utils'
import EditIcon from '@mui/icons-material/Edit'
import { gSx } from 'styles/Theme'
import ProfileEditDialog from './ProfileEditDialog'
import ProfilePhoto from './ProfilePhoto'
import ProfilePhotoEditDevOnly from './ProfilePhotoEditDevOnly'

export default function ProfileSettingsTab() {
  const fbUser = auth.currentUser
  const [showDialog, setDialog] = useState(false)

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader
        action={
          <Button variant='outlined'
            startIcon={<EditIcon fontSize='small' />}
            onClick={() => setDialog(true)}
          >
            Edit
          </Button>
        }
        title="Profile"
      />

      <Box sx={gSx.RowBetween}>
        <Box sx={sxBox}>
          <ProfilePhoto photoUrl={fbUser?.photoURL} width={100} />
          <Box sx={{ paddingX: '1vh' }} />
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'flex-start'
          }}>
            <Typography variant='h3'> {capitalizeEveryWord(fbUser?.displayName ?? '')}</Typography>
            <Typography>{fbUser?.email}</Typography>
          </Box>
        </Box>
      </Box>

      {
        showDialog &&
        <ProfileEditDialog
          open={showDialog}
          onClose={() => setDialog(false)}
        />
      }

      {/* <ProfilePhotoEditDevOnly /> */}
    </Card >
  )
}

const sxBox: SxProps = {
  display: 'flex',
  flexDirection: 'row',
}
