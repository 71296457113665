import React, { useEffect, useRef, useState } from 'react'
import { Box, Card, Typography, Link, List, ListItem, Pagination, Button } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { getEvents } from '../../service/eventApi'
import { useQuery } from '@tanstack/react-query'
import Grid from '@mui/material/Unstable_Grid2'
import EventListItem from './EventListItem'
import { useStore } from '../../hooks/useStore'
import SearchEventBar from './SearchEventBar'
import PaginationTitle from './PaginationTitle'
import { logInfo } from 'log'
import { ListEvents } from 'client'
import { ExistingEventsSkeleton } from 'components/Skeletons'
import EventsListTopRow from './EventsListTopRow'

export default observer(SharedEventsList)
function SharedEventsList() {
  const store = useStore()
  const params = store.User.getEventSearchParams()
  const [page, setPage] = useState(1)
  const res = useRef<ListEvents | undefined>(undefined)

  const { data, isLoading } = useQuery({
    queryKey: ['getSharedEvents', JSON.stringify(params), page],
    queryFn: async () => await getEvents(params, page, res.current, true),
  })

  useEffect(() => {
    res.current = data
  }, [data])

  const pageRange = data?.pages?.[0]

  const maxPages = Math.ceil((data?.total ?? 0) / (data?.page_limit ?? 1))

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    logInfo('Event List, date search, page button pressed', { page: value })
    setPage(value)
  }

  return (
    <Card>

      <SearchEventBar setPage={setPage} />
      <Box sx={{ margin: 2 }} />
      <EventsListTopRow />

      {
        isLoading ? (
          <ExistingEventsSkeleton />
        ) : (
          data?.results?.length != undefined && data?.results?.length > 0 ? (
            <>
              <List disablePadding={true} dense={true} >
                {data.results.map((d) => (
                  <ListItem key={d?.id} sx={{ width: '100%', padding: 0 }}>
                    <EventListItem data={d} key={d.id} />
                  </ListItem>
                ))}
              </List>

              {
                params.title
                  ? <PaginationTitle
                    page={page}
                    setPage={setPage}
                    pageRange={pageRange}
                  />
                  : <Pagination count={maxPages} page={page} onChange={handleChange} />
              }

            </>
          ) : (
            <Box sx={{ m: 5 }}>
              <Typography >No existing meetings found</Typography>

              {params.title &&
                <PaginationTitle
                  page={page}
                  setPage={setPage}
                  pageRange={pageRange}
                />
              }
            </Box>
          )
        )
      }

      <Box sx={{ padding: '5px' }} />
    </Card >
  )
}
