import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'
import { Typography, Checkbox, FormControlLabel, FormGroup, Divider } from '@mui/material'
import 'firebase/auth'
import { ActionItem, FlowInput, FlowInputConfig } from 'client'
import { MasterTask } from '../SelectTasks'
import Grid from '@mui/material/Unstable_Grid2'
import SalesforceFlowTaskTitle from './SalesforceFlowTaskTitle'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>
  tasks: MasterTask[]
}

export default function SalesforceFlowTasks({ input, tasks, setFlowInputs }: Props) {
  const queryClient = useQueryClient()

  const { data } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })

  const [selectedTasks, setSelectedTasks] = useState<ActionItem[]>([])

  const onChange = (task: MasterTask) => (event: ChangeEvent<HTMLInputElement>) => {
    const updatedTasks = event.target.checked
      ? [...selectedTasks, task]
      : selectedTasks.filter(t => t.id !== task.id)

    setSelectedTasks(updatedTasks)

    const newOutput: FlowInputConfig = { input, tasks: updatedTasks }
    setFlowInputs(prev => [...prev.filter(o => o.input !== input), newOutput])
  }

  return (
    <Grid container>
      <Grid xs={4}>
        <SalesforceFlowTaskTitle input={input} />
        <Typography variant='caption'>Optional</Typography>
      </Grid>
      <Grid xs={8}>

        <FormGroup>
          {tasks.map(task => {
            const date = new Date(task.due_date_datetime ?? '')
            const assignee = () => {
              if (task.assignee_info?.salesforce_user_id)
                return task.assignee_info.name
              return data?.name ?? ''
            }
            return (
              <FormControlLabel
                key={task.id}
                control={
                  <Checkbox
                    checked={selectedTasks.some(t => t.id === task.id)}
                    onChange={onChange(task)}
                  />
                }
                label={`${task.description} (Assignee: ${assignee()}, Due Date: ${date.toDateString()})`}
                sx={{ paddingY: 0.5 }}
              />
            )
          })}
        </FormGroup>

      </Grid>
      <Grid xs={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}