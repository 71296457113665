import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { Child } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'
interface Props {
  eventId?: string
  childListInput?: Child[]
  onUpdate: (updated: Child[]) => (void)
}

export default function ChildrenTable({ eventId, childListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [childList, setChildList] = useState<Child[]>([])
  const [newChild, setNewChild] = useState<Child>()

  useEffect(() => {
    if (childListInput) {
      setChildList(childListInput)
    }
  }, [childListInput])

  const columns = useMemo<MRT_ColumnDef<Child>[]>(
    () => [
      {
        accessorKey: 'First Name',
        header: 'First Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                'First Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['First Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Last Name',
        header: 'Last Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                'Last Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Last Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Gender',
        header: 'Gender',
        editSelectOptions: ["M", "F"],
        muiEditTextFieldProps: ({ row }) => ({
          select: true,
          onChange: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                Gender: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Gender'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 50
      },
      {
        accessorKey: 'Birthdate',
        header: 'Birthdate', // TODO: format check
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewChild((prevNewChild) => ({
                ...prevNewChild,
                Birthdate: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...childList]
            updated[rowIdx]['Birthdate'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [childList, newChild],
  )

  const handleCreate: MRT_TableOptions<Child>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...childList]
    if (newChild) {
      updated.push(newChild)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<Child>) {
    const updated = [...childList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: childList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewChild({}),
    onCreatingRowSave: handleCreate,
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true) // simplest way to open the create row modal with no default values
        }}
      >
        Create New User
      </Button>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="Children"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}
