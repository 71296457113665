import React from 'react'
import { Box, SxProps, Chip, Tooltip, Stack, CircularProgress } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { EventSimpleBase, ProcessingStatus, SalesforceApp } from '../../client'
import { Media } from 'service/media'

interface Props {
  data: EventSimpleBase
}
type Status = 'Processing Transcript' | 'No Transcript' | 'Transcript Error' | 'Select Meeting' | 'Processing Summary' | 'Done' | 'Unknown'

export default function EventStatusBar({ data }: Props) {
  const salesforceSent = !!(data.salesforce_flows_sent || data.salesforce_sent || data.salesforce_tasks_sent)
  const wealthboxSent = !!(data.wealthbox_sent || data.wealthbox_tasks_sent || data.wealthbox_workflows_sent)
  const redtailSent = !!(data.redtail_sent || data.redtail_tasks_sent || data.redtail_workflows_sent)

  const status = (): Status => {
    if (data.status?.transcript_status === ProcessingStatus.PROCESSING)
      return 'Processing Transcript'
    if (data.status?.transcript_status === ProcessingStatus.DONE_EMPTY_CONTENT)
      return 'No Transcript'
    if (data.status?.transcript_status === ProcessingStatus.ABORTED)
      return 'Transcript Error'
    if (data.status?.transcript_status === ProcessingStatus.DONE && !data.module_ui_title)
      return 'Select Meeting'
    if (data.status?.summary_overall_status === ProcessingStatus.PROCESSING)
      return 'Processing Summary'
    if (data.status?.summary_overall_status === ProcessingStatus.DONE)
      return 'Done'
    return 'Unknown' // could be: brand new, empty, yet to start event.
  }

  const salesforceApp = data.salesforce_app ?? SalesforceApp.SALESFORCE

  return (
    <Box sx={sxStatusRow}>
      {status() == 'Processing Transcript' &&
        <Chip
          label="Processing Transcript"
          variant="outlined"
          icon={
            <Stack direction="row" spacing={1}>
              <CircularProgress size={20} color="primary" />
            </Stack>
          }
        />
      }
      {status() == 'No Transcript' &&
        <Chip
          color="warning"
          label={'No Transcript'}
          variant='outlined'
        />
      }
      {status() == 'Transcript Error' &&
        <Chip
          color="error"
          label={'Transcript Error'}
          variant='outlined'
        />
      }
      {status() == 'Select Meeting' &&
        <Chip
          color="primary"
          label={'Select Meeting Type'}
          variant='outlined'
        />
      }
      {status() == 'Processing Summary' &&
        <Chip
          label="Processing Summary"
          variant="outlined"
          icon={
            <Stack direction="row" spacing={1}>
              <CircularProgress size={20} color="primary" />
            </Stack>
          }
        />
      }
      {status() == 'Done' &&
        <Chip
          label={data.module_ui_title}
          variant="outlined"
          icon={
            <Stack direction="row" spacing={1}>
              <CheckIcon sx={{ color: '#999' }} />
            </Stack>
          }
          sx={{ color: '#777' }}
        />
      }
      {redtailSent &&
        <Tooltip title='Redtail Sent'>
          <Box
            component="img"
            sx={{ width: 25, marginLeft: '10px' }}
            src={Media.redtailIconWhite}
            alt="Redtail"
          />
        </Tooltip>
      }
      {wealthboxSent &&
        <Tooltip title='Wealthbox Sent'>
          <Box
            component="img"
            sx={{ width: 25, marginLeft: '10px' }}
            src={Media.wealthboxIcon}
            alt="Wealthbox"
          />
        </Tooltip>
      }
      {salesforceSent &&
        <>
          {salesforceApp == SalesforceApp.SALESFORCE &&
            <Tooltip title={`${salesforceApp} Note Sent`}>
              <Box
                component="img"
                sx={{ width: 22, marginLeft: '10px' }}
                src={Media.salesforceIcon}
                alt="Salesforce"
              />
            </Tooltip>
          }
          {salesforceApp == SalesforceApp.PRACTIFI &&
            <Tooltip title={`${salesforceApp} Note Sent`}>
              <Box
                component="img"
                sx={{ width: 22, marginLeft: '10px' }}
                src={Media.practifiIcon}
                alt="Practifi"
              />
            </Tooltip>
          }
          {salesforceApp == SalesforceApp.XLR8 &&
            <Tooltip title={`${salesforceApp} Note Sent`}>
              <Box
                component="img"
                sx={{ width: 20, marginLeft: '10px' }}
                src={Media.xlr8Icon}
                alt="Xlr8"
              />
            </Tooltip>
          }
        </>
      }
    </Box>

  )
}

const sxStatusRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center',
  alignItems: 'center',
}