import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { Typography, Box, Skeleton, FormControl, Select, MenuItem, SelectChangeEvent, Snackbar, Alert } from '@mui/material'
import 'firebase/auth'
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row
} from 'material-react-table'
import { EventBase, WealthboxOutcome, WealthboxWorkflow } from 'client'
import { completeStepWealthboxWorkflows, getWealthboxSettings, getWealthboxWorkflows, sortWealthboxWorkflows, validateWorkflowStepsToComplete } from 'service/integrations/wealthboxApi'
import CheckIcon from '@mui/icons-material/Check'
import { gColor, gSx } from 'styles/Theme'
import { LoadingButton } from '@mui/lab'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import OverlayMessageOnComponent from '../OverlayMessageOnComponent'

interface Props {
  event: EventBase
  setExpanded: Dispatch<SetStateAction<boolean>>
}

// selectedOutcomes are tracked separately from complete step.
// useEffect change of "outcomes" or "complete step", the "setWorkflowStepsToComplete" gets updated by which outcomes are related. 
// every outcome id is unique even on same workflow step of different user.

export default function WealthboxWorkflowsCompleteStep({ event, setExpanded }: Props) {
  const queryClient = useQueryClient()
  const hasClient = !!event?.client?.id
  const { isBlocked } = useIsBlockedByPlan(true)
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({})
  const [workflowStepsToComplete, setWorkflowStepsToComplete] = useState<WealthboxWorkflow[]>([])
  const [selectedOutcomes, setSelectedOutcomes] = useState<WealthboxOutcome[]>([])

  const numSelected = Object.keys(rowSelection).length
  const disableSend = isBlocked || !hasClient || busy || numSelected == 0

  const { data: wb } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })

  const { data, isLoading } = useQuery({
    queryKey: ['getWealthboxWorkflows'],
    queryFn: async () => {
      const res = await getWealthboxWorkflows()
      return sortWealthboxWorkflows(res, event.client, wb?.user_id)
    }
  })

  useEffect(() => {
    let selectedRowData = table.getSelectedRowModel().rows.map((row) => row.original)

    // add outcome to checked complete step.
    selectedRowData = selectedRowData.map(row => {
      const availableOutcomes = row.active_step?.workflow_outcomes || []
      selectedOutcomes.forEach(selected => {
        const matchedOutcome = availableOutcomes.find(available => available.id === selected.id)
        if (matchedOutcome && row.active_step) {
          // Set the workflow_outcome_selected to the matched selected outcome
          row.active_step.workflow_outcome_selected = selected
        }
      })
      return row
    })

    setWorkflowStepsToComplete(selectedRowData)

    const validateSteps = validateWorkflowStepsToComplete(selectedRowData)
    setErr(validateSteps?.message)

  }, [rowSelection, selectedOutcomes])

  const columns: MRT_ColumnDef<WealthboxWorkflow>[] = [
    {
      accessorKey: 'linked_to.name', // access nested data with dot notation
      header: 'Related To',
      size: 120,
    },
    {
      accessorKey: 'active_step.name',
      header: 'Next Step',
      Cell: CellName,
      size: 180,
    },
    {
      accessorKey: 'active_step.assigned_to_name',
      header: 'Assigned To',
      size: 100,
    },
    {
      accessorKey: 'outcomes',
      header: 'Outcomes',
      size: 120,
      Cell: (props) => <CellOutcomes {...props} selectedOutcomes={selectedOutcomes} setSelectedOutcomes={setSelectedOutcomes} />,
    }
  ]

  const table = useMaterialReactTable({
    columns,
    data: data ?? [],
    enableFullScreenToggle: false,
    positionGlobalFilter: 'left',
    enableHiding: false,
    enableDensityToggle: false,
    enableSelectAll: false,
    enableRowSelection: hasClient,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
    initialState: {
      density: 'compact',
      columnOrder: [
        ...columns.map((col) => col.accessorKey as string),
        'mrt-row-select', // ID of the selection column
      ],
      pagination: {
        pageIndex: 0,
        pageSize: 5, // Set initial rows per page to 5
      },
    },
    localization: {
      select: 'Complete Step', // Change 'Select' or 'Actions' to 'Complete Step'
    },
  })

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const res = await completeStepWealthboxWorkflows(event?.id, workflowStepsToComplete)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      queryClient.invalidateQueries(['getEvent', event.id])
      queryClient.invalidateQueries(['getWealthboxWorkflowTemplates'])
      queryClient.invalidateQueries(['getWealthboxWorkflows'])
      setSnackSuccess(true)
      setExpanded(false)
    }
    setBusy(false)
  }

  return (
    <Box>
      <Typography>Select which Workflow Steps to complete.</Typography>
      <Box sx={{ padding: 1 }} />
      {isLoading
        ? <Skeleton variant="rectangular" width={'100%'} height={100} />
        : hasClient
          ? <MaterialReactTable table={table} />
          : <OverlayMessageOnComponent
            width='800px' height='250px' paddingTop='80px'
            overLayMessage={'Add Contact to Continue'}
          >
            <MaterialReactTable table={table} />
          </OverlayMessageOnComponent>
      }

      <Box sx={{ padding: 1 }} />
      {err && <Typography color={'tomato'}>{err}</Typography>}

      <Box sx={gSx.RowBetween}>
        <Box />
        <Box sx={gSx.Row}>
          <LoadingButton
            variant={'contained'}
            onClick={onSend}
            disabled={disableSend}
            loading={busy}
            sx={{ marginLeft: 3 }}
          >
            Complete Workflow Steps
          </LoadingButton>
        </Box>
      </Box>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Wealthbox Workflows Complete Step Done
        </Alert>
      </Snackbar>
    </Box>
  )
}

interface CellOutcomesProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflow>
  selectedOutcomes: WealthboxOutcome[]
  setSelectedOutcomes: (outcomes: WealthboxOutcome[]) => void
}

function CellOutcomes({ row, selectedOutcomes, setSelectedOutcomes }: CellOutcomesProps) {
  const available = row.original.active_step?.workflow_outcomes
  const match = available?.find(avail => selectedOutcomes?.some(selected => selected.id === avail.id))

  async function onChangeOutcome(event: SelectChangeEvent<string>) {
    if (!available)
      return
    const selected: WealthboxOutcome = JSON.parse(event.target.value)

    // remove all options(unique) from 1 workflow 
    const updatedOutcomes = selectedOutcomes.filter(outcome =>
      !available.some(av => av.id === outcome.id)
    )

    setSelectedOutcomes([...updatedOutcomes, selected])
  }

  if (!available || available.length == 0)
    return <></>

  return (
    <FormControl sx={{ width: 200 }}>
      <Select
        labelId="outcomes"
        id="outcomes"
        value={match ? JSON.stringify(match) : ''}
        onChange={onChangeOutcome}
        MenuProps={{ sx: { width: 200 } }}
      >
        {available?.map(a =>
          <MenuItem key={a.id} value={JSON.stringify(a)} sx={{ whiteSpace: "normal" }} >
            {a.name}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

interface CellNameProps {
  renderedCellValue: ReactNode
  row: MRT_Row<WealthboxWorkflow>
}

function CellName({ row }: CellNameProps) {
  const maxLength = 40

  let workflowName = row.original.name ?? ''
  workflowName = workflowName.length > maxLength ? workflowName.slice(0, maxLength) + "..." : workflowName

  let step = row.original.active_step?.name ?? ''
  step = step.length > maxLength ? step.slice(0, maxLength) + "..." : step
  return (
    <>
      <Typography variant='caption'>
        {workflowName}
      </Typography>
      <Typography>
        {step}
      </Typography>
    </>
  )
}
