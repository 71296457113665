import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { CertificateOfDeposit } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'

interface Props {
  eventId?: string
  certificateOfDepositListInput?: CertificateOfDeposit[]
  onUpdate: (updated: CertificateOfDeposit[]) => (void)
}

export default function CertificatesOfDepositTable({ eventId, certificateOfDepositListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [certificateOfDepositList, setCertificateOfDepositList] = useState<CertificateOfDeposit[]>([])
  const [newCD, setNewCD] = useState<CertificateOfDeposit>()

  useEffect(() => {
    if (certificateOfDepositListInput) {
      setCertificateOfDepositList(certificateOfDepositListInput)
    }
  }, [certificateOfDepositListInput])

  const columns = useMemo<MRT_ColumnDef<CertificateOfDeposit>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                Name: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 130
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                Owner: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Where Held?',
        header: 'Where Held?',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                ['Where Held?']: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Where Held?'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },

      {
        accessorKey: 'Interest Rate',
        header: 'Interest Rate',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                ['Interest Rate']: Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Interest Rate'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                ['Current Value']: Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Current Value'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Maturity Date',
        header: 'Maturity Date',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCD((prevNewData) => ({
                ...prevNewData,
                ['Maturity Date']: event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...certificateOfDepositList]
            updated[rowIdx]['Maturity Date'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [certificateOfDepositList, newCD],
  )

  const handleCreate: MRT_TableOptions<CertificateOfDeposit>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...certificateOfDepositList]
    if (newCD) {
      updated.push(newCD)
      onUpdate(updated)
    }
    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<CertificateOfDeposit>) {
    const updated = [...certificateOfDepositList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: certificateOfDepositList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewCD({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New Certificates of Deposit</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="Certificates Of Deposit"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}
