import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, SxProps, Link, Tooltip, Button, Skeleton, Divider } from '@mui/material'
import 'firebase/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from '../../styles/Theme'
import { Media } from 'service/media'
import CancelIcon from '@mui/icons-material/Cancel'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import AddToZoomButton from 'components/settings/AddToZoomButton'
import ContainerSmall from 'components/ContainerSmall'
import { logInfo } from 'log'
import IntegrationsBackButton from 'components/IntegrationsBackButton'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { LoadingButton } from '@mui/lab'
import { deauthorizeZoom, getZoomSettings } from 'service/integrations/zoomApi'
import Grid from '@mui/material/Unstable_Grid2'
import { config } from 'service/api'

export default function ZoomSettingsScreen() {
  const queryClient = useQueryClient()
  const { isBlocked } = useIsBlockedByPlan()
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const { data: zoom, isLoading, refetch } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
  })

  useEffect(() => {
    logInfo('Zoom Settings Screen')
  }, [])

  async function disconnect() {
    setErr(undefined)
    setBusy(true)
    const res = await deauthorizeZoom()
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await refetch()
    }
    setBusy(false)
  }

  return (
    <>
      <Box sx={{ padding: '1vh ' }} />
      <IntegrationsBackButton />
      <ContainerSmall>
        <Typography variant='h3'>Zoom Integration</Typography>
        <Divider sx={{ marginY: '2vh ' }} />
        <Box sx={gSx.Row}>
          <Link href="https://zoom.us" rel='noopener' target='_blank'>
            <Box
              component="img"
              sx={icon}
              src={Media.zoomLogo}
              alt="Zoom"
            />
          </Link>
          <Box sx={{ padding: 1 }} />
          <Typography variant='h1'>Zoom</Typography>
        </Box>
        <Box sx={{ padding: '1vh ' }} />

        <Typography>Connect FinMate to Zoom, so that the FinMate Notetaker can be invited to record your meetings</Typography>

        <Box sx={{ padding: '1vh ' }} />

        <Card>

          {
            isLoading
              ? <Skeleton variant="rectangular" width={'100%'} height={100} />
              : zoom?.isVideoReady ? (
                <>
                  <Box sx={gSx.RowBetween}>
                    <Typography>
                      {
                        zoom.profile?.email
                          ? `Signed in as ${zoom.profile?.email}`
                          : 'Connected'
                      }
                    </Typography>

                    <Tooltip title="Disconnect Zoom App">
                      <LoadingButton
                        aria-label='Disconnect Zoom App'
                        onClick={disconnect}
                        loading={busy}
                      >
                        <CancelIcon />
                      </LoadingButton>
                    </Tooltip>
                  </Box>

                  <Divider sx={{ marginY: 2 }} />

                  <Grid container rowSpacing={2} alignItems="center">
                    <Grid xs={1.5}>
                      <Box component="img" sx={sxLogoSmall} src={Media.zoomLogo} />
                    </Grid>
                    <Grid xs={3.5}>
                      <Typography variant='h6'>Zoom Video</Typography>
                    </Grid>
                    <Grid xs={7}>
                      <Typography>Ready</Typography>
                    </Grid>
                  </Grid>

                  {config.env != 'prod' && // hide from prod, zoom review can take a while
                    <Grid container rowSpacing={2} alignItems="center">
                      <Grid xs={1.5}>
                        <Box component="img" sx={sxLogoSmall} src={Media.zoomPhone} />
                      </Grid>
                      <Grid xs={3.5}>
                        <Typography variant='h6'>Zoom Phone</Typography>
                      </Grid>
                      <Grid xs={7}>
                        {zoom.hasApiAccess
                          ? zoom.isPhoneReady
                            ? <Typography>Ready</Typography>
                            : <Typography color={'darkred'}>Requires Zoom Phone Plan</Typography>
                          : <Typography color={'darkred'}>Requires Re-SignIn</Typography>
                        }
                      </Grid>
                    </Grid>
                  }

                </>
              ) : (
                <>
                  <Typography variant='h5'>Add to Zoom to Get Started</Typography>

                  <Typography sx={{ fontSize: 14, paddingY: '1vh' }}>
                    {`When adding to zoom, please checkmark "Allow this app to use my shared access permissions."`}
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    {
                      isBlocked ? (
                        <Typography color='tomato'>
                          Connecting to Zoom requires Starter or Standard Plan
                        </Typography>
                      ) : (
                        <AddToZoomButton />
                      )
                    }
                  </Box>
                </>
              )
          }

          {err && <Typography color={'tomato'} sx={{ padding: 2 }}>
            {err}
          </Typography>
          }

        </Card >

        <Button
          component="a"
          href="https://docs.finmate.ai/docs/zoom/setup"
          target="_blank"
          rel="noopener"
          startIcon={<TextSnippetIcon />}
        >
          Zoom Guide
        </Button>

      </ContainerSmall>
    </>
  )
}

const icon: SxProps = {
  width: 50,
  marginRight: 2
}

const sxLogoSmall: SxProps = {
  width: 38,
}
