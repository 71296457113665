import React, { useEffect } from 'react'
import { Link, Box, Button, Card, SxProps, Typography, LinearProgress } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { Media } from 'service/media'
import LinkIcon from '@mui/icons-material/Link'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { gSx } from 'styles/Theme'
import { setZoomAuthCred } from 'service/integrations/zoomApi'

/**
 * After connecting Zoom, zoom redirects to here with param code
 * @returns 
 */
export default function ZoomCallback() {
  const navigate = useNavigate()
  const url = window.location.href
  const urlParams = new URLSearchParams(new URL(url).search)
  const code = urlParams.get("code") ?? ''

  const { data, isLoading } = useQuery({
    queryKey: ['setZoomAuthCred'],
    queryFn: async () => await setZoomAuthCred(code),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (data && !(data instanceof Error) && data?.zoom_user_id) {
      // is authenticated.
      navigate(Path.ZoomSettings)
    }
  }, [data])

  return (
    <Box sx={gSx.CenterPage}>

      <Box sx={gSx.Row}>
        <Box component="img" sx={sxIcon}
          src={Media.logo100} alt="Finmate"
        />

        <LinkIcon sx={{ marginX: 3 }} />

        <Box component="img" sx={sxIcon}
          src={Media.zoomLogo} alt="Zoom"
        />
      </Box>

      {isLoading &&
        <Box sx={{ marginY: 5 }}>
          <Typography variant='h3'>Finalizing Finmate and Zoom Connections</Typography>
          <Box sx={{ margin: 2 }} />
          <LinearProgress />
        </Box>
      }

      {data instanceof Error &&
        <>
          <Typography variant='h3' sx={{ padding: 3 }}  >Something went wrong</Typography>
          <Typography color={'tomato'} sx={{ maxWidth: 500, margin: 1 }}>{data.message}</Typography>
        </>
      }

      <Button variant='outlined' sx={{ margin: 2 }}
        onClick={() => navigate(Path.ZoomSettings)}
        disabled={isLoading}
      >
        Zoom Settings
      </Button>

      <Button component="a" target="_blank" rel="noopener"
        href="https://docs.finmate.ai/docs/zoom/troubleshooting"
        startIcon={<TextSnippetIcon />} sx={{ margin: 1 }}
      >
        Zoom TroubleShooting
      </Button>
    </Box >
  )

}

const sxIcon: SxProps = {
  height: '50px'
}
