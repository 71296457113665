import React from 'react'
import { Box, SxProps, Card, Typography, CardHeader } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getUsage } from 'service'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory'
import { GraphDate, getUsageHistogram } from 'utils/eventAnalysis'
import { gColor } from 'styles/Theme'

interface LabelProps {
  datum: GraphDate
}
export default function Usage() {

  const { data } = useQuery({
    queryKey: ['getUsage'],
    queryFn: getUsage
  })
  const points = getUsageHistogram(6, data?.events)

  function labels({ datum }: LabelProps) {
    return datum.y > 0 ? `${datum.y.toFixed(1)} Hours` : '0 Hours'
  }

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>

      <CardHeader title="Usage" />

      <Box sx={{ width: '100%' }}>
        <VictoryChart width={600} height={150}>
          <VictoryBar
            style={{ data: { fill: gColor.green500 } }}
            x="x"
            y="y"
            data={points}
            labels={labels}
          />

          <VictoryAxis // x axis
            tickValues={points.map(point => point.x)}
            tickCount={points.length + 1}
            tickFormat={index => {
              if (!points || !points[index - 1])
                return ''
              const date = new Date(points[index - 1].xLabel)
              const locale = date.toLocaleString("default", { month: "short", year: 'numeric' })
              return locale
            }}
            style={{ tickLabels: { angle: 0 } }}
            domain={[0, points.length + 1]}
          />

          <VictoryAxis // y axis
            dependentAxis
            label="Hours Used"
            axisLabelComponent={<VictoryLabel dy={15} />}
            tickFormat={() => ""} // hide values
          />
        </VictoryChart>
      </Box>

    </Card >
  )
}
