import React, { useRef, useState, useEffect, createRef } from 'react'
import {
  Box,
  SxProps,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Tooltip,
  Chip,
  Avatar
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { AssigneeInfo, TranscriptEntryBase } from '../../client'
import { numSecToTime } from '../../utils'
import { gSx } from '../../styles/Theme'
import { useStore } from '../../hooks/useStore'
import { TranscriptSkeleton } from '../Skeletons'
import { useQuery } from '@tanstack/react-query'
import TranscriptSpeakerPopover from './TranscriptSpeakerPopover'
import { logInfo } from '../../log'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { getInitial } from 'utils'
import { getEventTranscript } from 'service/eventApi'

interface QueryError {
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

interface Props {
  eventId: string
  isPublic?: boolean
  disabled: boolean
}

interface HighlightTimer {
  id: NodeJS.Timeout
  idx: number
}

export default observer(TranscriptEditable)
function TranscriptEditable({ eventId, disabled, isPublic }: Props) {

  const store = useStore()
  const heightContactAndVideoCard = 520
  const [cardHeight, setCardHeight] = useState(window.innerHeight - heightContactAndVideoCard)
  useEffect(() => {
    const handleResize = () => setCardHeight(window.innerHeight - heightContactAndVideoCard)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // TODO use useRef below. using 'let' is bad practice for DOM.
  let boxRefs: any[] = []
  const containerRef = useRef<any>(null)
  const [isUserScrolling, setIsUserScrolling] = useState(false)
  let stopScrollTimer: any

  const [currentSpeaker, setCurrentSpeaker] = useState<AssigneeInfo>({})
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard')
  let copyTooltipTimer: any = null

  const { data, error, isLoading } = useQuery({
    queryKey: ['getEventTranscript', eventId],
    queryFn: async () => await getEventTranscript(eventId, isPublic),
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
  })

  // const seekSeconds = store.User.getSeekSeconds()
  const transcriptSeekSeconds = store.User.getTranscriptSeekSeconds()

  const timers = useRef<HighlightTimer[]>([])

  if (data?.entries?.length && data?.entries?.length > 0) {

    const tmpBoxRefs: any[] = []
    data?.entries?.map((s) => s?.text_list?.map((tx) => {
      tmpBoxRefs.push(createRef())
    }))
    boxRefs = tmpBoxRefs
  }

  const undoHighlight = (idx: number) => {
    if (boxRefs[idx]?.current) {
      boxRefs[idx].current.style.backgroundColor = 'transparent'
    }
  }

  function setHighlight(idx: number) {
    timers.current.forEach((timer) => {
      clearTimeout(timer.id)
      undoHighlight(timer.idx)
    })

    timers.current = []

    if (boxRefs[idx]?.current) {
      boxRefs[idx].current.style.backgroundColor = 'lightgoldenrodyellow'
    }

    const timerId = setTimeout(() => {
      const refIdx = idx
      undoHighlight(refIdx)
    }, 2000)

    timers.current.push({ id: timerId, idx })
  }

  useEffect(() => {
    let idx = 0
    // TODO: improve performance
    for (let i = 0; data?.entries && i < data?.entries?.length; i++) {
      const e = data?.entries[i]
      if (e.end_timestamp && e.end_timestamp < transcriptSeekSeconds) {
        continue
      }
      let found = false
      for (let j = 0; e?.text_list && j < e?.text_list?.length; j++) {
        const s = e?.text_list[j]
        if (!s.end_timestamp) {
          return
        }
        if (s.end_timestamp && s.end_timestamp >= transcriptSeekSeconds) {
          idx = s?.text_index ? s.text_index : i
          found = true
          break
        }
      }
      if (found) {
        break
      }
    }

    const container = containerRef.current
    const element = boxRefs[idx]?.current
    // auto scroll paused when user scrolling transcript + 3 secs, speaker popover open
    if (container && element && !isUserScrolling && anchorEl == null) {
      const containerTop = container.getBoundingClientRect().top
      const elementTop = element.getBoundingClientRect().top
      const offset = elementTop - containerTop + container.scrollTop - container.offsetHeight / 2

      container.scrollTo({
        top: offset,
        behavior: 'smooth',
      })
    }

    setHighlight(idx)
  }, [transcriptSeekSeconds])

  const handleSpeakerClickChip = (entry: TranscriptEntryBase, event: any) => {
    if (disabled || isPublic)
      return

    setCurrentSpeaker({
      speaker_id: entry.speaker_id,
      name: entry.speaker,
    })
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    const handleScroll = () => {
      setIsUserScrolling(true)
      clearTimeout(stopScrollTimer)
      stopScrollTimer = setTimeout(() => {
        setIsUserScrolling(false)
      }, 3000) // stop disable auto scroll after 3 secs
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  function copyText() {
    let plainText = ''

    const transcript = data?.entries as TranscriptEntryBase[]

    transcript.forEach(function (obj) {
      plainText += '[' + numSecToTime(obj.start_timestamp ?? 0) + '] ' + obj.speaker + ":\n"
      obj.text_list?.forEach(function (value) {
        plainText += value.text + ' '
      })
      plainText += '\n'
    })

    navigator.clipboard.writeText(plainText)
    setCopyTooltip('Copied!')
    if (copyTooltipTimer) {
      clearTimeout(copyTooltipTimer)
    }
    copyTooltipTimer = setTimeout(async () => {
      setCopyTooltip('Copy to clipboard')
    }, 2500)
    logInfo("Transcript Copied", { event_id: eventId, bot_id: eventId })
  }

  const sxCard: SxProps = {
    position: 'relative',
    height: cardHeight,
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
  }

  if (error) {
    const queryError = error as QueryError
    if (queryError.status === 410) {
      return (
        <Card sx={sxCard}>
          <Box sx={sxTitle}>
            <Typography variant='h4'>Transcript</Typography>
          </Box>
          <CardContent sx={sxExpired}>
            <Typography variant="h6" color="grey">Transcript Expired</Typography>
            <Box sx={{ padding: '2vh' }} />
          </CardContent>
        </Card>
      )
    }
  }

  // empty transcript case
  if (!isLoading && data?.entries?.length == 0) {
    return (
      <Card sx={sxCard}>
        <Box sx={sxTitle}>
          <Typography variant='h4'>Transcript</Typography>
        </Box>
        <CardContent sx={sxContent}>
          <Typography sx={{ color: 'gray' }}>No transcript</Typography>
        </CardContent>
      </Card >
    )
  }

  return (
    <Card sx={sxCard}>
      <Box sx={sxTitle}>
        <Typography variant='h4'>Transcript</Typography>
        {!isLoading && (
          <Tooltip title={copyTooltip}>
            <IconButton aria-label='copy' onClick={copyText}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <CardContent sx={sxContent} ref={containerRef}>

        <Box sx={{ padding: '2px' }} />

        {isLoading ? (
          <TranscriptSkeleton />
        ) : (
          data?.entries?.map((e, i) => (
            <Box key={i} sx={{ paddingTop: '12px', paddingBotton: '0px' }}>
              <Box sx={gSx.RowBetween}>

                <Chip
                  label={e.speaker}
                  avatar={<Avatar>{getInitial(e.speaker)}</Avatar>}
                  variant="outlined"
                  sx={sxSpeakerChip}
                  onClick={(event) => handleSpeakerClickChip(e, event)}
                />

                <Button
                  variant='text'
                  style={{
                    padding: '2px',
                    lineHeight: '1',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => {
                    store.User.setSeekSeconds(e.start_timestamp ?? 0)
                    store.User.setTranscriptSeekSeconds(e.start_timestamp ?? 0)
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      lineHeight: '1.2',
                      padding: '0px',
                      margin: '0px',
                    }}
                  >
                    {' '}
                    {numSecToTime(e.start_timestamp ?? 0)}
                  </Typography>
                </Button>
              </Box>
              <Box sx={{ padding: '2px' }} />
              <Box sx={{ paddingLeft: '6px' }}>
                {(
                  e?.text_list?.map((sentence, idx) => (
                    <Typography key={sentence?.text_index} variant='body1' sx={sxText} ref={boxRefs[sentence?.text_index ? sentence.text_index : 0]}>
                      {sentence?.text}
                    </Typography>
                  )))
                }
              </Box>

            </Box>
          ))
        )}
        <TranscriptSpeakerPopover
          eventId={eventId}
          speaker={currentSpeaker}
          anchorEl={anchorEl}
          handleClose={() => setAnchorEl(null)}
        />

      </CardContent>
    </Card >

  )
}

const sxContent: SxProps = {
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingX: 0,
}

const sxText: SxProps = {
  display: 'inline',
  mr: 1
}

const sxSpeakerChip: SxProps = {
  fontSize: 14,
  fontWeight: 'bold',
  cursor: 'pointer',
  '&:hover': {
    color: "primary.main"
  },
}

const sxTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 0.5
}

const sxExpired: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}
