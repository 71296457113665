import React from 'react'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'
import { Checkbox, IconButton, Tooltip } from '@mui/material'

export type CheckboxTriType = 'filled' | 'clear' | 'partial'

interface Props {
  state: CheckboxTriType
  onClick: () => void
}

export default function CheckboxTriState({ state, onClick }: Props) {

  return (
    <>
      {state == 'clear' &&
        <Tooltip title='Select All'>
          <Checkbox checked={false} onClick={onClick} />
        </Tooltip>
      }

      {state == 'partial' &&
        <Tooltip title='Deselect All'>
          <IconButton onClick={onClick}>
            <IndeterminateCheckBoxOutlinedIcon />
          </IconButton>
        </Tooltip>
      }

      {state == 'filled' &&
        <Tooltip title='Deselect All'>
          <Checkbox checked={true} onClick={onClick} />
        </Tooltip>
      }
    </>
  )
}