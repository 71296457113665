import React, { useEffect, useState } from 'react'
import { Card, Typography, Box, Button, Snackbar, Alert, LinearProgress, CardHeader } from '@mui/material'
import { getUserConfig, updateAutoshareAll } from '../../../service'
import { logInfo } from '../../../log'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { gSx } from 'styles/Theme'
import CheckIcon from '@mui/icons-material/Check'
import Grid from '@mui/material/Unstable_Grid2'
import AutoShareDialog from './AddAutoShareDialog'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import UserAccessLineItemSettings from './UserAccessLineItemSettings'
import { SharedUser } from 'client'
import { SkeletonRows } from 'components/Skeletons'

export default function AutoShareCard() {
  const queryClient = useQueryClient()
  const [openDialog, setOpenDialog] = useState(false)
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)

  const { data: userConfig, isLoading } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
  })

  const allShared = userConfig?.auto_share ?? []

  async function onSave(all: SharedUser[]) {
    logInfo('Share Event, modify user permissions. Save button pressed.', all)
    setErr(undefined)
    setBusy(true)
    const res = await updateAutoshareAll(all)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
      setSnackSuccess(true)
    }
    setBusy(false)
  }

  return (
    <Card sx={{ flexGrow: 1, margin: 0 }}>
      <CardHeader title='Auto Share Meetings' />

      <Box sx={gSx.RowBetween}>
        <Typography>
          Share future meetings with selected users such as your assistant.
        </Typography>

        <Button variant='outlined'
          onClick={() => setOpenDialog(true)}
        >
          <PersonAddIcon />
        </Button>
      </Box>

      <Box sx={{ padding: 2 }} />

      <Grid container spacing={2}>
        <Grid xs={5}>
          <Typography variant='h6'>Name</Typography>
        </Grid>
        <Grid xs={4}>
          <Typography variant='h6'>Access</Typography>
        </Grid>
        <Grid xs={3}>
          <Typography variant='h6'>Email Updates</Typography>
        </Grid>

        {isLoading
          ? <SkeletonRows count={2} height={50} />
          : allShared?.map(sharedUser =>
            <UserAccessLineItemSettings
              key={sharedUser.uid}
              userConfig={sharedUser}
              disabled={false}
              allShared={allShared}
              onSave={onSave}
            />
          )}
      </Grid>

      <Box sx={{ padding: 1 }} />

      <Typography variant='caption'>Email Updates: Send the user an email when meeting selection summary is ready and when summary has finished processing.</Typography>

      {busy && <LinearProgress />}
      {err && <Typography color={'tomato'} >{err}</Typography>}

      <AutoShareDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Auto Share Permissions Sucessfully Updated
        </Alert>
      </Snackbar>

    </Card>

  )
}
