import React, { useEffect, useState } from 'react'
import { Typography, Box, Card, Switch, Link, Tooltip, Snackbar, Alert, SxProps, Dialog, DialogContent, DialogActions, Button, IconButton, useMediaQuery, LinearProgress } from '@mui/material'
import { CalendarEvent } from '../../client'
import { gSx } from '../../styles/Theme'
import { UpdateCalendarMeeting } from '../../service/calendarApi'
import Grid from '@mui/material/Unstable_Grid2'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import CheckIcon from '@mui/icons-material/Check'
import { Media } from 'service/media'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Path } from 'routes'
import { useNavigate } from 'react-router-dom'
import SubCard from 'components/SubCard'
import { getZoomSettings } from 'service/integrations/zoomApi'

interface CalEventProp {
  calEvent: CalendarEvent
  isSmall?: boolean
}

export default function CalendarEventSimple({ calEvent, isSmall }: CalEventProp) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const time = (new Date(calEvent.start_time ?? '')).toLocaleTimeString()
  const isXS = useMediaQuery('(max-width:900px)')

  const [record, setRecord] = useState(calEvent.will_record)
  const { isBlocked } = useIsBlockedByPlan()
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [showPremeeting, setShowPremeeting] = useState(false)

  const { data: zoom } = useQuery({
    queryKey: ['getZoomSettings'], queryFn: getZoomSettings,
    enabled: queryClient.getQueryData(['getZoomSettings']) === undefined,
  })

  const url = calEvent.meeting_url
  const requireZoomNotConnected = !zoom?.isVideoReady && !!(url?.includes('zoom.us'))
  const isZoomWebinar = url?.includes('zoom.us/webinar/register')

  const disableRecord = isBlocked || !url || url?.length == 0 || isPastStartTime(calEvent) || requireZoomNotConnected || isZoomWebinar || busy

  useEffect(() => {
    if (requireZoomNotConnected)
      setRecord(false)
  }, [])

  async function onRecord(switchElement: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setErr(undefined)
    setRecord(checked)
    setBusy(true)

    calEvent.override_should_record = checked
    const res = await UpdateCalendarMeeting(calEvent)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
    }
    setBusy(false)
  }
  function onShowPremeeting() {
    setShowPremeeting(true)
  }

  function onClosePremeeting() {
    setShowPremeeting(false)
  }

  function switchTooltip() {
    if (isBlocked)
      return 'Requires Starter or Standard Plan'
    if (isPastStartTime(calEvent))
      return 'Past Start time, Use Manual Join'
    if (requireZoomNotConnected)
      return 'Requires Zoom Connection'
    if (isZoomWebinar)
      return 'Cannot join webinars'
    if (!url)
      return "Missing meeting link"
    return "Schedule Recording"
  }

  function Badges() {
    return (
      <Box sx={gSx.Row}>
        {calEvent.is_redtail &&
          <Tooltip title="Redtail Calendar">
            <Box
              component="img"
              sx={sxIcon}
              src={Media.redtailIconWhite}
              alt="Redtail"
            />
          </Tooltip>
        }
        {calEvent.is_wealthbox &&
          <Tooltip title="Wealthbox Calendar">
            <Box
              component="img"
              sx={sxIcon}
              src={Media.wealthboxIcon}
              alt="Wealthbox"
            />
          </Tooltip>
        }
        {calEvent.premeeting_html &&
          <Tooltip title="Pre-meeting Note">
            <IconButton onClick={onShowPremeeting} sx={sxIcon}><EventNoteIcon /></IconButton>
          </Tooltip>
        }
      </Box>
    )
  }

  return (
    <SubCard>
      <Grid container>
        <Grid xs={8} md={6}>

          <Typography variant='h6'>{calEvent.title}</Typography>

          <Typography>{time}</Typography>

          <Link rel="noopener" target="_blank" href={url} sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>{url}</Link>

          {requireZoomNotConnected &&
            <Button variant='outlined' sx={{ display: 'block', marginTop: 2 }}
              onClick={(() => navigate(Path.ZoomSettings))} >
              Connect Zoom to Schedule Recording
            </Button>
          }
          {err && <Typography color={'tomato'} >{err}</Typography>}
        </Grid>

        <Grid xs={4}>
          <Typography variant='h6'>Attendees</Typography>
          {calEvent.attendees?.map(a =>
            <Typography key={a.email}>{a.email ?? a.name}{a.is_organizer && ' (host)'}</Typography>
          )}
        </Grid>

        <Grid xs={12} md={isSmall ? 12 : 2} >
          {(isXS || isSmall) ? (
            <Box sx={gSx.RowBetween}>
              <Tooltip title={switchTooltip()}>
                <Box sx={gSx.Row}>
                  <Switch checked={record} inputProps={{ 'aria-label': 'controlled' }}
                    onChange={onRecord} disabled={disableRecord}
                  />
                  <Typography variant='body2'>Record</Typography>
                </Box>
              </Tooltip>
              <Badges />
            </Box>
          ) : (
            <Box sx={sxColumnBetween}>
              <Tooltip title={switchTooltip()}>
                <Box sx={gSx.Row}>
                  <Typography variant='body2'>Record</Typography>
                  <Switch checked={record} inputProps={{ 'aria-label': 'controlled' }}
                    onChange={onRecord} disabled={disableRecord}
                  />
                </Box>
              </Tooltip>
              <Badges />
            </Box>
          )}
        </Grid >
      </Grid >

      {busy && <LinearProgress />}

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Record Preference Updated Succesfully
        </Alert>
      </Snackbar>

      <Dialog open={showPremeeting} fullWidth maxWidth="md">
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{ __html: calEvent.premeeting_html ?? "" }}
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              padding: '16px',
              overflow: 'auto',
              backgroundColor: '#fcfcfc',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosePremeeting} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </SubCard>
  )
}

function isPastStartTime(event: CalendarEvent) {
  if (!event.start_time || !event.bot_id)
    return false
  const start = new Date(event?.start_time)
  return start <= new Date()
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
  marginRight: 1
}

const sxColumnBetween: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  height: '100%',
}
