import React, { useEffect, useState } from 'react'
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getSystemSettings } from 'service/systemApi'
import { sxSideNavListItemButton } from './SideNavItem'
import CampaignIcon from '@mui/icons-material/Campaign'
import { WhatsNewRecent } from 'components/CustomIcons'
import { getUserConfig, viewedWhatsNew } from 'service'

export default function WhatsNew() {
  const queryClient = useQueryClient()

  const [isNew, setNew] = useState(false)

  const { data: system, isLoading: isLoadingSystem } = useQuery({
    queryKey: ['getSystemSettings'], queryFn: getSystemSettings,
    enabled: queryClient.getQueryData(['getSystemSettings']) === undefined,
  })

  const { data: userConfig, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['getUserConfig'], queryFn: getUserConfig,
    enabled: queryClient.getQueryData(['getUserConfig']) === undefined,
  })

  useEffect(() => {
    if (isLoadingSystem || isLoadingConfig)
      return
    if (system?.whats_new_latest_blog) {
      const latestBlog = new Date(system.whats_new_latest_blog)

      if (userConfig?.whats_new_last_viewed) {
        const lastViewed = new Date(userConfig?.whats_new_last_viewed)
        if (latestBlog > lastViewed)
          setNew(true)
      } else {
        setNew(true)
      }
    }
  }, [system, userConfig])

  async function onClick() {
    window.open('https://docs.finmate.ai/blog', '_blank', 'noopener,noreferrer')
    await viewedWhatsNew()
    await queryClient.invalidateQueries({ queryKey: ['getUserConfig'] })
    setNew(false)
  }

  return (
    <Box sx={{ paddingY: '1px' }}>
      <ListItemButton
        onClick={onClick}
        sx={sxSideNavListItemButton}
      >
        <ListItemIcon sx={{ color: 'lightslategrey', minWidth: '38px' }} >

          {isNew
            ? <WhatsNewRecent />
            : <CampaignIcon />
          }
        </ListItemIcon>

        <ListItemText primary={`What's New`} />
      </ListItemButton>
    </Box >
  )
}
