import React, { useState, useEffect, useMemo, useRef } from 'react'

import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { CollegeFund } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'

interface Props {
  eventId?: string
  collegeFundListInput?: CollegeFund[]
  onUpdate: (updated: CollegeFund[]) => (void)
}

export default function CollageFundTable({ eventId, collegeFundListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [collegeFundList, setCollegeFundList] = useState<CollegeFund[]>([])
  const [newCollegeFund, setNewCollegeFund] = useState<CollegeFund>()

  useEffect(() => {
    if (collegeFundListInput) {
      console.log("collegeFundList", collegeFundListInput)
      setCollegeFundList(collegeFundListInput)
    }
  }, [collegeFundListInput])

  const columns = useMemo<MRT_ColumnDef<CollegeFund>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCollegeFund((prevNewFund) => ({
                ...prevNewFund,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...collegeFundList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCollegeFund((prevNewFund) => ({
                ...prevNewFund,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...collegeFundList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Beneficiary',
        header: 'Beneficiary',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCollegeFund((prevNewFund) => ({
                ...prevNewFund,
                'Beneficiary': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...collegeFundList]
            updated[rowIdx]['Beneficiary'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },

      {
        accessorKey: 'Contributions',
        header: 'Contributions',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCollegeFund((prevNewFund) => ({
                ...prevNewFund,
                'Contributions': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...collegeFundList]
            updated[rowIdx]['Contributions'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Current Balance',
        header: 'Current Balance',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewCollegeFund((prevNewFund) => ({
                ...prevNewFund,
                'Current Balance': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...collegeFundList]
            updated[rowIdx]['Current Balance'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [collegeFundList, newCollegeFund],
  )

  const handleCreate: MRT_TableOptions<CollegeFund>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...collegeFundList]
    if (newCollegeFund) {
      updated.push(newCollegeFund)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<CollegeFund>) {
    const updated = [...collegeFundList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: collegeFundList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewCollegeFund({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New College Fund</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="College Funds"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}
