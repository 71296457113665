import React, { useEffect, useState, ReactNode } from 'react'
import { observer } from 'mobx-react-lite'
import { EditorState } from 'prosemirror-state'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { EditorContent, useEditor } from '@tiptap/react'
import { Alert, Box, Card, CardContent, IconButton, Snackbar, SxProps, Tooltip, Typography, Divider } from '@mui/material'
import { logInfo } from '../../log'
import { EditorMenuBar } from './EditorMenuBar'
import { htmlToPlainText } from 'utils'
import SearchAndReplace from './SearchAndReplace'
import './Editor.css'

interface Props {
  eventId: string | null
  title: string | null
  htmlContent: string | null
  disabled: boolean
  headerComponents?: ReactNode
  onSave: (edittedHtml: string) => void
  onCopyToClipboard: (edittedHtml: string) => string
}

export default observer(TextEditorCard)
function TextEditorCard({ eventId, title, htmlContent, disabled, headerComponents, onSave, onCopyToClipboard }: Props) {
  const edittedTimer: any = null // Declare timer outside the function scope
  const [isEdit, setEdit] = useState(false)
  const [edittedHtml, setEdittedHtml] = useState("")
  const [showSaveNotif, setShowSaveNotif] = useState(false)

  function setEditMode() {
    setEdit(!isEdit)
    if (editor) {
      // reset history
      const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema,
      })
      editor.view.updateState(newEditorState)
      editor.commands.focus("start")
      logInfo(title + " Edit Started", { event_id: eventId })
    }
  }

  async function closeEdit() {
    if (edittedTimer != null) {
      // clear current timer if exist
      clearTimeout(edittedTimer)
    }
    if (editor) {
      const edittedHtml = editor.getHTML()
      onSave(edittedHtml)
    }
    setShowSaveNotif(true)
    setEdit(false)
    logInfo(title + " Edit Finished", { event_id: eventId })
  }

  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard')
  let copyTooltipTimer: any = null

  useEffect(() => {
    editor?.setEditable(isEdit)
  }, [isEdit])

  useEffect(() => {
    if (edittedHtml) {
      editor?.commands.setContent(edittedHtml)
    }
  }, [edittedHtml])

  useEffect(() => {
    if (htmlContent) {
      setEdittedHtml(String(htmlContent))
    }
  }, [htmlContent])

  function copyText() {
    const html = onCopyToClipboard(edittedHtml)
    const plainText = htmlToPlainText(html)
    const blob = new Blob([html], { type: 'text/html' })
    const text = new Blob([plainText], { type: "text/plain" })
    const clipboardItem = new window.ClipboardItem({ 'text/html': blob, "text/plain": text })
    navigator.clipboard.write([clipboardItem])

    setCopyTooltip('Copied!')
    if (copyTooltipTimer) {
      clearTimeout(copyTooltipTimer)
    }
    copyTooltipTimer = setTimeout(async () => {
      setCopyTooltip('Copy to clipboard')
    }, 2500)
    logInfo(title + " Copied", { event_id: eventId })
  }

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount,
      SearchAndReplace.configure(),
    ],
    editable: false,
    content: edittedHtml,
    editorProps: {
      attributes: {
        class: 'custom-editor-class',
        style: 'outline-color: #45CBDC; padding: 8px' // Apply styles directly
      }
    },
  })

  return (
    <Card sx={sxCard} >
      <Box sx={sxTitle}>

        <Box sx={sxTitleText}>
          <Typography variant='h4'>{title}</Typography>
        </Box>

        <Box>
          {isEdit ? (
            <>
              <Tooltip title='Save and Exit Edit'>
                <IconButton aria-label='save and close' onClick={closeEdit}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Box sx={sxMenu}>

              {headerComponents}

              <Tooltip title={copyTooltip}>
                <IconButton aria-label='copy' onClick={copyText}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title='Edit'>
                <IconButton
                  aria-label='edit'
                  onClick={() => setEditMode()}
                  disabled={disabled}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

            </Box>
          )}
          <Snackbar
            open={showSaveNotif}
            autoHideDuration={3000}
            onClose={() => setShowSaveNotif(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              variant="filled"
              onClose={() => setShowSaveNotif(false)}
              severity='success'
              sx={{ width: '100%' }}
            >
              Saved!
            </Alert>
          </Snackbar>
        </Box>

      </Box>

      {isEdit && eventId &&
        <Box sx={menuBarBox}>
          <EditorMenuBar editor={editor} />
        </Box>
      }

      <CardContent sx={sxContent} >
        <Box
          sx={{
            border: '1px solid rgba(128, 128, 128, 0.25)', // Set the border width, style, and color
            borderRadius: '4px',      // Optional: Add border-radius for rounded corners

          }}
        >
          <EditorContent className='tiptap' editor={editor} />
        </Box>
        <Box sx={{ padding: '2px' }} />

        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.characters() : 0} characters
        </Typography>
        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>
          {editor ? editor.storage.characterCount.words() : 0} words
        </Typography>
      </CardContent>
    </Card >
  )
}

const sxCard: SxProps = {}

const sxTitle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}

const sxMenu: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}

const sxTitleText: SxProps = {
  display: 'flex',
  alignItems: 'center',
  gap: 1,
}

const sxContent: SxProps = {
  overflowX: 'hidden',
  height: '72vh',
  paddingTop: "1px",
}

const menuBarBox: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}