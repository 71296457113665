import React, { useEffect, useRef, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, SxProps, Typography } from '@mui/material'
import { logErr, logInfo } from '../../../log'
import { EventBase, WealthboxType, WealthboxVisibility } from '../../../client'
import { Media } from '../../../service/media'
import { Path } from '../../../routes'
import { useNavigate } from 'react-router-dom'
import { getWealthboxSettings } from 'service/integrations/wealthboxApi'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import WealthboxContactCard from './WealthboxContactCard'
import WealthboxNotesCard from './WealthboxNotesCard'
import WealthboxTasksCard from './WealthboxTasksCard'
import WealthboxWorkflowsCard from './WealthboxWorkflowsCard'
import { gSx } from 'styles/Theme'

interface Props {
  open: boolean
  onClose: () => void
  event: EventBase
  eventSummaryHtml: string
}

export default function WealthboxDialog({ open, onClose, event, eventSummaryHtml }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isBlocked } = useIsBlockedByPlan(true)

  const [wbType, setWbType] = useState<WealthboxType>(WealthboxType.PERSON)

  const { data: wbSettings } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })

  useEffect(() => {
    if (open) {
      logInfo('Wealthbox NoteSender Dialog Open')
      queryClient.invalidateQueries({ queryKey: ['getEvent', event?.id] })
    }
  }, [open])

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}
      PaperProps={{ style: { height: '98vh', margin: 0, maxHeight: '98vh' } }}
    >
      <DialogTitle sx={gSx.Row}>
        <Box component="img" sx={icon} src={Media.wealthboxIcon} alt="Wealthbox" />
        <Typography variant='h3'>Send to Wealthbox</Typography>
      </DialogTitle>

      <DialogContent>
        {isBlocked &&
          <Typography sx={sxText}>{`Please `}
            <Link onClick={() => navigate(Path.Billing)}>Upgrade your plan</Link>
          </Typography>
        }
        <Box sx={{ padding: 1 }} />

        <WealthboxContactCard
          event={event}
          wbType={wbType}
          setWbType={setWbType}
        />

        <WealthboxNotesCard
          event={event}
          eventSummaryHtml={eventSummaryHtml}
          wbType={wbType}
        />

        <WealthboxTasksCard
          event={event}
          wbType={wbType}
        />

        <WealthboxWorkflowsCard
          event={event}
          wbType={wbType}
          wbConfig={wbSettings}
        />
      </ DialogContent >

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog >
  )
}

const icon: SxProps = {
  width: 40,
  height: 40,
  marginRight: 2
}

const sxText: SxProps = {
  paddingY: '5px',
  cursor: 'pointer'
}
