import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Typography, Box, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem, TextField, Checkbox, ButtonBase, IconButton } from '@mui/material'
import 'firebase/auth'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { EventBase, RedtailCategory, RedtailTeam, RedtailUser, RedtailWorkflow, RedtailWorkflowTemplate } from 'client'
import { LoadingButton } from '@mui/lab'
import CheckIcon from '@mui/icons-material/Check'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import { gSx } from 'styles/Theme'
import { createRedtailWorkflow, getRedtailCategories, getRedtailSettings, getRedtailTeams, getRedtailUsers, getRedtailWorkflowTemplates, OwnerType } from 'service/integrations/redtailApi'
import { SkeletonRows } from 'components/Skeletons'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import CharacterCount from '@tiptap/extension-character-count'
import { EditorMenuBar } from 'components/editor/EditorMenuBar'
import { EditorState } from 'prosemirror-state'
import { grey } from '@mui/material/colors'
import Grid from '@mui/material/Unstable_Grid2'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { EditorAddContent } from 'components/editor/EditorAddContent'

interface Props {
  event: EventBase
  setExpanded: Dispatch<SetStateAction<boolean>>
}

export default function RedtailWorkflowsCreate({ event, setExpanded }: Props) {
  const queryClient = useQueryClient()
  const hasClient = !!event?.client?.id
  const { isBlocked } = useIsBlockedByPlan(true)
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  const [template, setTemplate] = useState<RedtailWorkflowTemplate>()
  const [workflowName, setWorkflowName] = useState('')
  const [owner, setOwner] = useState<OwnerType>('User')
  const [category, setCategory] = useState<RedtailCategory>()
  const [user, setUser] = useState<RedtailUser>()
  const [team, setTeam] = useState<RedtailTeam>()
  const [date, setDate] = useState<dayjs.Dayjs>(dayjs().add(3, 'day'))
  const [noWeekend, setNoWeekend] = useState(true)
  const description = useRef('')
  const runOnce = useRef(false)
  const disableSend = isBlocked || !hasClient || busy || !template

  const { data: rt } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
    enabled: queryClient.getQueryData(['getRedtailSettings']) === undefined,
  })

  const { data: categories } = useQuery({
    queryKey: ['getRedtailCategories'], queryFn: getRedtailCategories,
    enabled: queryClient.getQueryData(['getRedtailCategories']) === undefined,
  })

  const { data: teams } = useQuery({
    queryKey: ['getRedtailTeams'], queryFn: getRedtailTeams,
    enabled: queryClient.getQueryData(['getRedtailTeams']) === undefined,
  })

  const { data: users } = useQuery({
    queryKey: ['getRedtailUsers'], queryFn: getRedtailUsers,
    enabled: queryClient.getQueryData(['getRedtailUsers']) === undefined,
  })

  const { data: templates, isLoading } = useQuery({
    queryKey: ['getRedtailWorkflowTemplates'], queryFn: getRedtailWorkflowTemplates,
  })

  useEffect(() => {
    if (users && rt) {
      const u = users.find(u => u.id == rt.user_id)
      setUser(u)
    }
  }, [users, rt])

  useEffect(() => {
    if (templates instanceof Error) setErr(templates.message)
  }, [templates])

  useEffect(() => {
    if (categories instanceof Error) setErr(categories.message)
  }, [categories])

  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount,
    ],
    editable: true,
    content: description.current,
    editorProps: {
      attributes: {
        class: 'custom-editor-class',
        style: 'outline-color: #45CBDC; padding: 8px' // Apply styles directly
      },
    },
  })

  useEffect(() => {
    if (template) {
      if (template.name)
        setWorkflowName(template.name)
      if (template.description) {
        editor && editor.commands.setContent(template.description)
        // setDescription(template.description)
      }
      if (template.target_date)
        setDate(dayjs(template.target_date))
      if (template.exclude_weekends !== undefined) {
        setNoWeekend(template.exclude_weekends)
      }
      if (categories && template?.category_id && !(categories instanceof Error)) {
        const findCategory = categories.find(c => c.id == template.category_id)
        setCategory(findCategory)
      }
    }
  }, [editor, template, categories])

  useEffect(() => {
    if (editor) {
      editor.view.dom.style.borderRadius = '5px'
      editor.view.dom.style.border = '1px solid'
      editor.view.dom.style.borderColor = grey[500]
      editor.on('blur', () => {
        editor.view.dom.style.borderColor = grey[500]
      })

      editor.on('focus', () => {
        editor.view.dom.style.borderColor = '#45CBDC'
      })
    }

    if (editor && runOnce.current == false) {
      const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema
      })
      editor.view.updateState(newEditorState)
      runOnce.current = true
    }
  }, [editor])

  async function onSend() {
    setBusy(true)
    setErr(undefined)

    const wf: RedtailWorkflow = {
      workflow_template_id: template?.redtail_one_id,
      name: workflowName,
      due_date: date.toISOString(),
      description: editor ? editor.getHTML() : '',
      assigned_user_type: owner == 'User' ? 1 : 2,
      exclude_weekends: noWeekend,
      contact_id: event.client?.redtail_id,
      category_id: category?.id
    }
    if (owner == 'User') {
      wf.assigned_user_id = user?.id
    } else if (owner == 'Team') {
      wf.assigned_user_id = team?.id
    }

    const res = await createRedtailWorkflow(event.id, wf)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
      await queryClient.invalidateQueries(['getEvent', event.id])
      setExpanded(false)
    }
    setBusy(false)
  }

  if (isLoading)
    return <SkeletonRows count={8} height={50} />

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>

      <Box sx={gSx.Row}>
        <Typography variant='h5'>Create a Workflow</Typography>

        <IconButton onClick={() => window.open('https://docs.finmate.ai/docs/Redtail/Workflows', '_blank', 'noopener,noreferrer')}>
          <HelpOutlineOutlinedIcon fontSize='small' color='info' />
        </IconButton>
      </Box>

      <Box sx={{ padding: 1 }} />
      <Typography>{`Linked Contact: ${event.client?.first_name} ${event.client?.last_name}`}</Typography>

      <Box sx={{ padding: 1 }} />
      <FormControl fullWidth required >
        <InputLabel id="select-template">Select Template</InputLabel>
        <Select
          labelId="select-template" id="select-template" label="Select Template"
          value={template ? JSON.stringify(template) : ''}
          onChange={(e) => setTemplate(JSON.parse(e.target.value))}
        >
          {templates && !(templates instanceof Error) && templates?.map(t =>
            <MenuItem key={t.redtail_one_id} value={JSON.stringify(t)}>{t.name}</MenuItem>
          )}
        </Select>
      </FormControl>

      <Box sx={{ padding: 1 }} />

      <TextField id="workflow-name" label="Workflow Name" variant="outlined"
        value={workflowName} onChange={(e) => setWorkflowName(e.target.value)}
      />

      <Box sx={{ padding: 1 }} />

      <Grid container spacing={2}>
        <Grid xs={4}>
          <FormControl fullWidth>
            <InputLabel id="owner">Owner</InputLabel>
            <Select
              labelId="owner" id="owner" label="Select Owner"
              value={owner}
              onChange={(e) => setOwner(e.target.value as OwnerType)}
            >
              <MenuItem value={'User'}>User</MenuItem>
              <MenuItem value={'Team'}>Team</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={8}>
          {owner == 'User' &&
            <FormControl fullWidth>
              <InputLabel id="user">User</InputLabel>
              <Select
                labelId="user" id="user" label="Select User"
                value={user ? JSON.stringify(user) : ''}
                onChange={(e) => setUser(JSON.parse(e.target.value))}
              >
                {users && users.map(u =>
                  <MenuItem key={u.id} value={JSON.stringify(u)}>{u.first_name} {u.last_name}</MenuItem>
                )}
              </Select>
            </FormControl>
          }

          {owner == 'Team' &&
            <FormControl fullWidth>
              <InputLabel id="team">Team</InputLabel>
              <Select
                labelId="team" id="team" label="Select Team"
                value={team ? JSON.stringify(team) : ''}
                onChange={(e) => setTeam(JSON.parse(e.target.value))}
              >
                {teams && teams.map(t =>
                  <MenuItem key={t.id} value={JSON.stringify(t)}>{t.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>

      <Box sx={{ padding: 1 }} />

      <FormControl>
        <InputLabel id="owner">Category</InputLabel>
        <Select
          labelId="Category" id="Category" label="Select Category"
          value={category ? JSON.stringify(category) : ''}
          onChange={(e) => setCategory(JSON.parse(e.target.value))}
        >
          {categories && !(categories instanceof Error) && categories.map(c =>
            <MenuItem key={c.id} value={JSON.stringify(c)}>{c.name}</MenuItem>
          )}
        </Select>
      </FormControl>

      <Box sx={{ padding: 1 }} />

      <Box sx={gSx.Row}>
        <DatePicker label="Due Date (Target Date)"
          value={date}
          onChange={(value) => value && setDate(value)}
          sx={{ width: '200px' }}
        />
        <Box sx={{ paddingX: 3 }} />

        <Checkbox checked={noWeekend}
          onChange={() => setNoWeekend((prev) => !prev)}
        />
        <ButtonBase onClick={() => setNoWeekend((prev) => !prev)}>
          <Typography>Excluding Weekends & Federal Holidays</Typography>
        </ButtonBase>
      </Box>

      <Box sx={{ padding: 2 }} />

      <Box sx={gSx.Row}>
        <EditorMenuBar editor={editor} />
        <EditorAddContent editor={editor} eventId={event.id} />
      </Box>

      <Box sx={{ padding: 0.5 }} />

      <EditorContent editor={editor} />

      <Box sx={{ padding: 1 }} />

      {err && <Typography color={'tomato'}>{err}</Typography>}

      <Box sx={gSx.RowBetween}>
        <Box />
        <Box sx={gSx.Row}>
          <LoadingButton
            variant={'contained'}
            onClick={onSend}
            disabled={disableSend}
            loading={busy}
            sx={{ marginLeft: 3 }}
          >
            Start Workflows
          </LoadingButton>
        </Box>
      </Box>

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Redtail Workflows started
        </Alert>
      </Snackbar>
    </Box>
  )
}
