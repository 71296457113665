import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import {
  MaterialReactTable,
  MRT_Table,
  MRT_TableContainer,
  useMaterialReactTable,
  MRT_EditActionButtons,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
} from 'material-react-table'
import { PersonalProperty } from '../../client'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import SubCard from 'components/SubCard'

interface Props {
  eventId?: string
  personalPropertyListInput?: PersonalProperty[]
  onUpdate: (updated: PersonalProperty[]) => (void)
}

export default function PersonalPropertyTable({ eventId, personalPropertyListInput, onUpdate }: Props) {

  const cellRef = useRef(null)
  const [personalPropertyList, setPersonalPropertyList] = useState<PersonalProperty[]>([])
  const [newPersonalProperty, setNewPersonalProperty] = useState<PersonalProperty>()

  useEffect(() => {
    if (personalPropertyList) {
      console.log("personalPropertyList", personalPropertyList)
      setPersonalPropertyList(personalPropertyList)
    }
  }, [personalPropertyList])

  const columns = useMemo<MRT_ColumnDef<PersonalProperty>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewPersonalProperty((prevNew) => ({
                ...prevNew,
                'Name': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...personalPropertyList]
            updated[rowIdx]['Name'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Type',
        header: 'Type',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewPersonalProperty((prevNew) => ({
                ...prevNew,
                'Type': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...personalPropertyList]
            updated[rowIdx]['Type'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Owner',
        header: 'Owner',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewPersonalProperty((prevNew) => ({
                ...prevNew,
                'Owner': event.target.value,
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...personalPropertyList]
            updated[rowIdx]['Owner'] = event.target.value
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Current Value',
        header: 'Current Value',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewPersonalProperty((prevNew) => ({
                ...prevNew,
                'Current Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...personalPropertyList]
            updated[rowIdx]['Current Value'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
      {
        accessorKey: 'Purchase Value',
        header: 'Purchase Value',
        muiEditTextFieldProps: ({ row }) => ({
          onChange: (event) => {
            if (row.index < 0) {
              setNewPersonalProperty((prevNew) => ({
                ...prevNew,
                'Purchase Value': Number(event.target.value),
              }))
              return
            }
            const rowIdx = Number(row.index)
            const updated = [...personalPropertyList]
            updated[rowIdx]['Purchase Value'] = Number(event.target.value)
            onUpdate(updated)
          },
        }),
        size: 100
      },
    ],
    [personalPropertyList, newPersonalProperty],
  )

  const handleCreate: MRT_TableOptions<PersonalProperty>['onCreatingRowSave'] = async ({
    values,
    table,
  }) => {
    const updated = [...personalPropertyList]
    if (newPersonalProperty) {
      updated.push(newPersonalProperty)
      onUpdate(updated)
    }

    table.setCreatingRow(null) // exit creating mode
  }

  function handleDelete(row: MRT_Row<PersonalProperty>) {
    const updated = [...personalPropertyList]
    if (row.index >= 0) {
      updated.splice(row.index, 1)
      onUpdate(updated)
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: personalPropertyList,
    editDisplayMode: 'table', // ('modal', 'row', 'table', and 'custom' are also available)
    createDisplayMode: 'row', // default ('row', and 'custom' are also available)
    enableEditing: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableFullScreenToggle: true,
    enableTopToolbar: true,
    onCreatingRowCancel: () => setNewPersonalProperty({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">Add New Personal Property</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  })

  return (
    <SubCard>
      <CardHeader
        title="Personal Properties"
        sx={{ paddingTop: 1 }}
        action={
          <Tooltip title="Add">
            <IconButton
              onClick={() => {
                table.setCreatingRow(true)
              }}
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <MRT_TableContainer table={table} />
    </SubCard>
  )
}

const sxCard: SxProps = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '75vh',
}
