import React, { ReactNode, useState } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Typography,
  ListItemIcon,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material'
import { gSx } from 'styles/Theme'
import { useNavigate } from 'react-router-dom'
import { Path } from 'routes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { formatDate } from 'utils'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CalendarEvents } from 'client'
import VideocamIcon from '@mui/icons-material/Videocam'
import { Media } from 'service/media'
import { getRedtailCalendarDb, syncRedtailCalendar } from 'service/integrations/redtailApi'
import { blue, green } from '@mui/material/colors'

interface Props {
  title: ReactNode
  calEvents?: CalendarEvents
}
export default function CalendarMenu({ calEvents, title }: Props) {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null)
  const open = Boolean(openMenu)
  const [busy, setBusy] = useState<string | undefined>()
  const [err, setErr] = useState<string | undefined>()
  const [warn, setWarn] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()

  const onOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget)
  }

  const { data: rtCal } = useQuery({
    queryKey: ['getRedtailCalendarDb'],
    queryFn: getRedtailCalendarDb,
  })
  const rtDate = rtCal && rtCal.last_updated && new Date(rtCal.last_updated)

  async function onRedtailSync() {
    setBusy('Redtail Syncing. This may take 1-2 minutes')
    setErr(undefined)
    setWarn(undefined)
    const res = await syncRedtailCalendar()
    if (res instanceof Error) {
      if (res.message.includes('Rate limited')) {
        setWarn('Redtail was recently updated. Try again in a bit.')
      } else {
        setErr(res.message)
      }
    } else {
      setOpenSuccessSnack('Redtail Calendar Synced Successfully')
      queryClient.invalidateQueries({ queryKey: ['getRedtailCalendarDb'] })
      setOpenMenu(null)
    }
    setBusy(undefined)
  }

  return (
    <>
      <Box sx={gSx.RowBetween}>
        {title}
        <IconButton onClick={onOpenMenu}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {busy && (
        <Box sx={{ ...gSx.Row, paddingY: 2 }}>
          <CircularProgress size={20} sx={{ marginX: 2 }} />
          <Typography>{busy}</Typography>
        </Box>
      )}

      {err && (
        <Typography color={'tomato'} sx={{ paddingY: 2 }}>
          {err}
        </Typography>
      )}
      {warn && (
        <Typography color={green[600]} sx={{ paddingY: 2 }}>
          {warn}
        </Typography>
      )}

      <Menu
        id='basic-menu'
        anchorEl={openMenu}
        open={open}
        onClose={() => setOpenMenu(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(Path.RecorderSettings)}>
          <ListItemIcon>
            <VideocamIcon />
          </ListItemIcon>
          Recorder Settings
        </MenuItem>
        {calEvents?.is_redtail_signed_in && (
          <MenuItem onClick={onRedtailSync}>
            <Box sx={gSx.Row}>
              <ListItemIcon>
                <Box component='img' sx={icon} src={Media.redtailIconWhite} alt='Redtail' />
              </ListItemIcon>
              <Box>
                <Typography>Redtail Sync</Typography>
                {rtDate && (
                  <Typography variant='caption'>last synced: {formatDate(rtDate)}</Typography>
                )}
              </Box>
            </Box>
          </MenuItem>
        )}
      </Menu>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSuccessSnack(undefined)}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>
    </>
  )
}

const icon: SxProps = {
  width: 23,
  height: 23,
  marginRight: '5px',
}
