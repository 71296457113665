import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  SxProps, Card, TextField, Skeleton, Box, CardHeader, IconButton, Tooltip, Snackbar, Alert, Typography, CardContent,
  Button, ButtonBase, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
} from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Api } from '../../service/api'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { logInfo, logErr } from '../../log'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { ClientData, PersonalDetails, PersonalProperty, BankAccount, InvestmentAccount, CertificateOfDeposit, CollegeFund, RetirementPlanEmployer, RetirementPlans, RetirementPlanIndividual, Pension, Client, Child } from '../../client'
import ChildrenTable from '../finances/ChildrenTable'
import PersonalPropertyTable from '../finances/PersonalPropertyTable'
import BankAccountsTable from '../finances/BankAccountsTable'
import InvestmentAccountsTable from '../finances/InvestmentAccountsTable'
import CertificatesOfDepositTable from 'components/finances/CertificatesOfDepositTable'
import CollageFundTable from 'components/finances/CollageFundTable'
import RetirementPlansEmployerTable from 'components/finances/RetirementPlansEmployerTable'
import RetirementPlansIndividualTable from 'components/finances/RetirementPlansIndividualTable'
import PensionTable from 'components/finances/PensionTable'
import ClientInfo from 'components/finances/ClientInfo'
import { Media } from '../../service/media'
import { LoadingButton } from '@mui/lab'
import DownloadIcon from '@mui/icons-material/Download'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { getErrorMessage } from "utils"
import { EventFinancesSkeletonPage } from 'components/Skeletons'

interface Props {
  eventId?: string
  isModuleSelected?: boolean
  isPublic?: boolean
}

export default function EventFinances({ eventId, isModuleSelected, isPublic }: Props) {
  const queryClient = useQueryClient()
  const cellRef = useRef(null)
  const heightTitle = 170
  const [cardHeight, setCardHeight] = useState(window.innerHeight - heightTitle)

  const [busy, setBusy] = useState(false)
  const [busyKyc, setBusyKyc] = useState(false)
  const [isFullScreen, setFullScreen] = useState(false)

  const [clientData, setClientData] = useState<ClientData>()
  const [openErrorSnack, setOpenErrorSnack] = useState<string | undefined>()
  const [openSuccessSnack, setOpenSuccessSnack] = useState<string | undefined>()

  const { data, isLoading } = useQuery({
    queryKey: ['getClientData', eventId],
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs,
    refetchOnWindowFocus: !clientData,
  })

  async function runQuery() {
    if (eventId) {
      const api = await Api()
      const res = await api.precisefp.getClientData(eventId)
      if (res) {
        setClientData(res)
      }
      return res
    }
  }

  async function downloadKyc() {
    setBusyKyc(true)
    if (eventId) {
      const api = await Api()
      const pdfUrl = await api.fidelityca.buildKycPdf(eventId)

      const link = document.createElement('a')
      link.href = pdfUrl // Update the path if necessary
      link.target = "_blank"
      link.rel = "noopener noreferrer"
      const filename = new URL(pdfUrl).pathname.split('/').pop()
      if (filename)
        link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setBusyKyc(false)
  }

  async function handleUpdate() {
    setBusy(true)
    if (eventId) {
      try {
        const api = await Api()
        const res_person = await api.precisefp.postPrecisePfPerson(eventId)
        const res_profile = await api.precisefp.postPrecisePfProfile(eventId)
        setOpenSuccessSnack("Successfully synced with PreciseFP")
      } catch (e) {
        const err = getErrorMessage(e)
        logErr("handleUpdate failed", { err })
        setOpenErrorSnack(err.message)
      }
    }
    setBusy(false)
  }

  async function updatePfClientData(eventId: string, updatedClientData: ClientData) {
    setBusy(true)
    try {
      const api = await Api()
      const res = await api.precisefp.updateClientData(eventId, updatedClientData)
      await queryClient.invalidateQueries({ queryKey: ['getClientData', eventId] })
      setOpenSuccessSnack("Updated")
    } catch (e) {
      const err = getErrorMessage(e)
      logErr("updatePfClientData failed", { err })
      setOpenErrorSnack(err.message)
    }
    setBusy(false)
  }

  async function onUpdatedClientInfo(idx: number, clientInfo: Client) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Clients && updatedClientData.Clients[idx])
        updatedClientData.Clients[idx] = clientInfo

      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedChildren(updatedChildList: Child[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Family Members'])
        updatedClientData['Family Members'].Children = updatedChildList

      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedBankAccounts(updatedBankAccounts: BankAccount[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Bank Accounts'] = updatedBankAccounts
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedCD(updatedCD: CertificateOfDeposit[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Certificates of Deposit'] = updatedCD
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateInvestmentAccounts(updated: InvestmentAccount[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Taxable Investment Accounts'] = updated
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdateCollageFund(updated: CollegeFund[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['College Funds'] = updated
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatePersonalProperty(updated: PersonalProperty[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData.Assets)
        updatedClientData.Assets['Personal Property'] = updated
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedRetirementPlansEmployer(type: string, updatedRPEs: RetirementPlanEmployer[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'][type as keyof RetirementPlans] = updatedRPEs
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatedRetirementPlanIndividual(type: string, updatedRPEs: RetirementPlanIndividual[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'][type as keyof RetirementPlans] = updatedRPEs
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  async function onUpdatePensions(updatedPensions: Pension[]) {
    if (eventId) {
      const updatedClientData = { ...clientData }
      if (updatedClientData['Retirement Plans'])
        updatedClientData['Retirement Plans'].Pensions = updatedPensions
      await updatePfClientData(eventId, updatedClientData)
    }
  }

  const sxCard: SxProps = {
    height: cardHeight,
    minHeight: 520,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    transition: "all 0.3s ease-in-out",
  }

  const sxCardFull: SxProps = {
    position: "fixed",
    overflowY: 'scroll',
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1300,
    overflow: "auto",
    transition: "all 0.3s ease-in-out",

  }

  if (isLoading || !clientData) {
    return (
      <Card sx={sxCard}>
        <CardHeader
          title="Client's Finances"
          sx={{ paddingTop: 1 }}
        />
        <EventFinancesSkeletonPage />
      </Card>
    )
  }

  return (
    <Card sx={isFullScreen ? sxCardFull : sxCard}>
      <CardHeader
        title="Client's Finances"
        sx={{ paddingTop: 1 }}
        action={
          <Box sx={sxRow}>
            <LoadingButton loading={busyKyc} variant="outlined" startIcon={<DownloadIcon />} onClick={downloadKyc}>KYC Pdf</LoadingButton>

            <LoadingButton loading={busy} variant="outlined" startIcon={<Box component="img"
              sx={icon} src={Media.precisefpIcon} />} onClick={handleUpdate}>Precise FP</LoadingButton>

            <IconButton aria-label="full screen" onClick={() => setFullScreen(!isFullScreen)}>
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>

        }
      />

      <CardContent sx={{ padding: 0 }}>
        <Typography
          variant="h4"
          sx={stickyHeading}
        >
          Client & Co-Client
        </Typography>

        {clientData?.Clients?.map((client: Client, idx) => (
          <>
            <ClientInfo eventId={eventId} clientInfo={client} index={idx} onUpdated={onUpdatedClientInfo} />
          </>
        ))}

        <Typography
          variant="h4"
          sx={stickyHeading}
        >
          Family Members
        </Typography>

        <ChildrenTable eventId={eventId} childListInput={clientData?.['Family Members']?.Children} onUpdate={onUpdatedChildren} />

        <Typography
          variant="h4"
          sx={stickyHeading}
        >
          Assets
        </Typography>

        <BankAccountsTable eventId={eventId} bankAccountListInput={clientData?.Assets?.['Bank Accounts']} onUpdate={onUpdatedBankAccounts} />

        <CertificatesOfDepositTable eventId={eventId} certificateOfDepositListInput={clientData?.Assets?.['Certificates of Deposit']} onUpdate={onUpdatedCD} />

        <InvestmentAccountsTable eventId={eventId} investmentAccountListInput={clientData?.Assets?.['Taxable Investment Accounts']} onUpdate={onUpdateInvestmentAccounts} />

        <CollageFundTable eventId={eventId} collegeFundListInput={clientData?.Assets?.['College Funds']} onUpdate={onUpdateCollageFund} />

        <PersonalPropertyTable eventId={eventId} personalPropertyListInput={clientData?.Assets?.['Personal Property']} onUpdate={onUpdatePersonalProperty} />

        <Typography
          variant="h4"
          sx={stickyHeading}
        >
          Retirement Plans
        </Typography>

        <RetirementPlansEmployerTable title="401(k)" type={'401(k)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['401(k)']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansEmployerTable title="Roth 401(k)" type={'Roth 401(k)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['Roth 401(k)']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansEmployerTable title="403(b)" type={'403(b)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['403(b)']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansEmployerTable title="457(b)" type={'457(b)'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['457(b)']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansEmployerTable title="Simple IRA" type={'Simple IRA'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['Simple IRA']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansEmployerTable title="SEP IRA" type={'SEP IRA'} eventId={eventId} retirementPlanEmployerListInput={clientData?.['Retirement Plans']?.['SEP IRA']} onUpdate={onUpdatedRetirementPlansEmployer} />

        <RetirementPlansIndividualTable title="IRA" type={'IRA'} eventId={eventId} retirementPlanIndividualListInput={clientData?.['Retirement Plans']?.IRA} onUpdate={onUpdatedRetirementPlanIndividual} />

        <RetirementPlansIndividualTable title="Roth IRA" type={'Roth IRA'} eventId={eventId} retirementPlanIndividualListInput={clientData?.['Retirement Plans']?.['Roth IRA']} onUpdate={onUpdatedRetirementPlanIndividual} />

        <PensionTable eventId={eventId} pensionListInput={clientData?.['Retirement Plans']?.Pensions} onUpdate={onUpdatePensions} />

      </CardContent >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openErrorSnack}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnack(undefined)}
      >
        <Alert variant='filled' severity='error'>
          {openErrorSnack}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!openSuccessSnack}
        autoHideDuration={5000}
        onClose={() => setOpenSuccessSnack(undefined)}
      >
        <Alert variant='filled' severity='success'>
          {openSuccessSnack}
        </Alert>
      </Snackbar>
    </Card >
  )
}

const sxRow: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  gap: 2
}

const icon: SxProps = {
  width: 20,
  height: 20,
  marginRight: '5px'
}

const stickyHeading: SxProps = {
  position: 'sticky',
  top: -25,
  backgroundColor: 'white',
  zIndex: 1,
  padding: '16px 0px',
}