import React from 'react'
import Grid from '@mui/material/Unstable_Grid2'

import {
  Box,
  Card,
  Skeleton,
  SxProps,
  Typography,
} from '@mui/material'

export function TranscriptSkeleton() {
  return (
    <>
      <Box sx={{ padding: '10px' }} />
      <Skeleton variant="rectangular" width={80} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />

      <Box sx={{ padding: '10px' }} />
      <Skeleton variant="rectangular" width={80} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />

      <Box sx={{ padding: '10px' }} />
      <Skeleton variant="rectangular" width={80} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />

      <Box sx={{ padding: '10px' }} />
      <Skeleton variant="rectangular" width={80} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />
      <Skeleton variant="rectangular" width={'90%'} height={20} />

    </>
  )
}

interface SkeletonRowsProps {
  count: number
  height: number
}
export function SkeletonRows({ count, height }: SkeletonRowsProps) {
  return (
    Array.from({ length: count }).map((_, index) => (
      <Skeleton key={index} variant="rectangular" width="100%" height={height} />
    ))
  )
}

export function CalendarShortSkeletons() {
  return (
    <>
      <Skeleton variant="rectangular" width={'95%'} height={100} />
      <Skeleton variant="rectangular" width={'95%'} height={100} />
      <Skeleton variant="rectangular" width={'95%'} height={100} />
    </>
  )
}

export function OrgUsersSkeleton() {
  const skeletonHeight = 50
  return (
    <>
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
    </>
  )
}

export function ExistingEventsSkeleton() {

  return (
    <>
      <ExistingEventsSkeletonRow />
      <ExistingEventsSkeletonRow />
      <ExistingEventsSkeletonRow />
      <ExistingEventsSkeletonRow />
    </>
  )
}

function ExistingEventsSkeletonRow() {

  return (
    <Grid container spacing={2} sx={{ padding: '25px' }}>
      <Grid xs={7} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Skeleton variant="rectangular" width={80} height={60} />
        <Skeleton variant="rectangular" width={200} height={20} sx={{ marginX: '10vh' }} />
      </Grid>
      <Grid xs={3}>
        <Skeleton variant="rectangular" width={150} height={20} />
      </Grid>
      <Grid xs={2}>
        <Skeleton variant="rectangular" width={100} height={20} />
      </Grid>
    </Grid>
  )
}

export function EventSkeletonTranscriptInProgressPage() {
  return (
    <>
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Typography textAlign={'center'}>We are preparing the meeting transcript.</Typography>
      <Typography textAlign={'center'}>We will notify you when it is ready to pick the meeting type.</Typography>
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
    </>
  )
}

export function EventSkeletonTranscriptInProgressPageForFollowupEmail() {
  return (
    <Card sx={sxCard}>
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Typography textAlign={'center'}>We are preparing the meeting transcript.</Typography>
      <Typography textAlign={'center'}>We will notify you when it is ready to build Followup Email.</Typography>
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
    </Card>
  )
}

export function EventSkeletonSummaryInProgressPage() {
  return (
    <Card sx={sxCard}>
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Typography textAlign={'center'}>We are processing the meeting.</Typography>
      <Typography textAlign={'center'}>We will notify you when it is ready!</Typography>
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
    </Card>
  )
}

export function EventSkeletonPage() {
  return (
    <>
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'20%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
    </>
  )
}

export function EventFinancesSkeletonPage() {
  return (
    <>
      <Skeleton variant="rectangular" width={'100%'} height={15} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={15} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={15} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
      <Skeleton variant="rectangular" width={'100%'} height={20} />
    </>
  )
}

export function CustomizerLoading() {
  const skeletonHeight = 20
  return (
    <Card>
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'30%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Box sx={{ padding: '2vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'30%'} height={skeletonHeight} />
    </Card>
  )
}

export function TemplatePageLoading() {
  const skeletonHeight = 100
  return (
    <Card>
      <Box sx={{ padding: '3vh' }} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
      <Skeleton variant="rectangular" width={'100%'} height={skeletonHeight} />
    </Card>
  )
}
const sxCard: SxProps = {}