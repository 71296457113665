import React, { useEffect, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { SxProps, Tab } from '@mui/material'
import ClientCardForEvent from 'components/client/ClientCardForEvent'
import Attendees from './Attendees'
import { EventBase } from 'client'

interface Props {
  data?: EventBase
  disabled?: boolean
}

export default function ClientAttendeeTabs({ data, disabled }: Props) {
  const [tabValue, setTabValue] = useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }

  const hasAttendees = data?.attendees && data.attendees.length > 0

  return (
    <TabContext value={tabValue}>
      <TabList sx={sxTabList} onChange={handleChange}>
        <Tab
          sx={sxTabList}
          label='Contact'
          value='1'
        />
        {
          hasAttendees &&
          <Tab
            sx={sxTabList}
            label='Attendees'
            value='2'
          />
        }

      </TabList>
      <TabPanel sx={sxTabPanel} value='1'>
        <ClientCardForEvent event={data} disabled={disabled} />
      </TabPanel>
      <TabPanel sx={sxTabPanel} value='2'>
        <Attendees data={data} />
      </TabPanel>
    </TabContext>
  )
}

const sxTabList: SxProps = {
  minHeight: '30px',
  marginLeft: 0.5
}

const sxTabPanel: SxProps = {
  padding: '0',
}