import React from 'react'
import { Typography, Skeleton, CardHeader } from '@mui/material'
import { numActiveOrgUsersByPlan } from '../../service/orgsApi'
import { OrgBase, ProductName } from 'client'
import Grid from '@mui/material/Unstable_Grid2'

interface Props {
  org?: OrgBase
  isLoading: boolean
}
export default function ActiveOrgSeats({ org, isLoading }: Props) {

  return (
    <>
      <CardHeader title="Active Seats" />
      <Grid container>

        <Grid xs={11}>
          <Typography>Standard Plan</Typography>
          <Typography>Starter Plan</Typography>
          <Typography>Lite Plan</Typography>
        </Grid>
        <Grid xs={1}>
          {isLoading
            ? <Skeleton variant="rectangular" width={'100%'} height={40} />
            : (
              <>
                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.STANDARD, org?.org_user_list)}
                </Typography>

                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.STARTER, org?.org_user_list)}
                </Typography>

                <Typography>
                  {numActiveOrgUsersByPlan(ProductName.LITE, org?.org_user_list)}
                </Typography>
              </>
            )
          }
        </Grid>
      </Grid>
    </>
  )
}