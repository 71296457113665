/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ZoomBase } from '../models/ZoomBase';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ZoomService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Valid Auth
     * @returns ZoomBase Successful Response
     * @throws ApiError
     */
    public getZoomSettings(): CancelablePromise<ZoomBase> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/integrations/zoom/auth',
        });
    }

    /**
     * Set Zoom Auth Cred
     * @param zoomCode
     * @returns ZoomBase Successful Response
     * @throws ApiError
     */
    public setZoomAuthCred(
        zoomCode: string,
    ): CancelablePromise<ZoomBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/integrations/zoom/auth',
            query: {
                'zoom_code': zoomCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deauthorize Zoom
     * @returns any Successful Response
     * @throws ApiError
     */
    public deauthorizeZoom(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/integrations/zoom/auth',
        });
    }

}
