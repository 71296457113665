import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../routes'
import { Box, SxProps, Typography, ListItemButton, Checkbox, Divider, ButtonBase } from '@mui/material'
import { formatDateByIso } from '../../utils/date'
import Grid from '@mui/material/Unstable_Grid2'
import { EventSimpleBase } from '../../client'
import { isImageURLReal } from '../../utils'
import useWindowWidth from 'hooks/useWindowWidth'
import { gSx } from 'styles/Theme'
import EventStatusBar from './EventStatusBar'

interface Props {
  data: EventSimpleBase
  checked?: boolean
  onChecked?: (checked: boolean) => void
}

export default function EventListItem({ data, checked, onChecked }: Props) {
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const thumbnailUrl = data.video_bucket_info?.thumbnail_filename_signed
  const [isReal, setReal] = useState(false)

  useEffect(() => {
    async function checkImage() {
      setReal(thumbnailUrl ? await isImageURLReal(thumbnailUrl) : false)
    }
    checkImage()
  }, [])

  const sxTitle: SxProps = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: `${windowWidth - 100}px`
  }

  function onClick() {
    navigate(`${Path.Event}/${data.id}`)
  }

  return (
    <Box sx={{ width: '100%' }}>

      <Box sx={gSx.Row}>

        <ListItemButton
          sx={{ display: 'flex', flexDirection: 'column' }}
          aria-label={data.title}
          onClick={onClick}
          disableRipple
        >
          <Grid container width='100%' sx={{ paddingY: 1 }}>

            <Grid xs={12} md={7}>
              <Box sx={gSx.Row}>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                  {onChecked
                    ? <Checkbox aria-label='notify checkbox' checked={checked}
                      onClick={(e) => {
                        e.stopPropagation()
                        onChecked(!checked)
                      }} />
                    : <Box sx={{ paddingX: 2.6 }} />
                  }
                </Box>

                <ButtonBase
                  sx={{
                    display: { xs: 'none', md: 'block' },
                    alignContent: 'center',
                  }}
                >
                  {isReal && thumbnailUrl && thumbnailUrl?.length > 0
                    ? <Box component="img" sx={sxThumbnail} src={thumbnailUrl} />
                    : <Box sx={sxThumbnail} />
                  }
                </ButtonBase>

                <Box sx={{ width: '100%' }}>
                  <Typography sx={sxTitle}>{data.title}</Typography>
                  <EventStatusBar data={data} />
                </Box>
              </Box>
            </Grid>

            <Grid xs={8} md={3} >
              <Box sx={{ width: '100%' }}>
                <Typography sx={sxText}>
                  {`${data.client?.first_name ?? ''} ${data.client?.last_name ?? ''}`}
                </Typography>
                <Typography sx={sxText}>{data.client?.email ?? ''}</Typography>
              </Box>
            </Grid>

            <Grid xs={4} md={2} >
              <Typography textAlign={'start'} variant='body1'>
                {formatDateByIso(data.start_time)}
              </Typography>
            </Grid>
          </Grid>

        </ListItemButton>
      </Box>

      <Divider sx={{ width: '100%' }} />
    </Box >

  )
}

const sxThumbnail: SxProps = {
  width: 70,
  height: 55,
  backgroundColor: 'grey.100',
  borderRadius: '10px',
  marginRight: 2,
}

const sxText: SxProps = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}
