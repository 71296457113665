import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DashboardIcon from '@mui/icons-material/Dashboard'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, SxProps, List, Typography, ButtonBase, Divider } from '@mui/material'
import { Path } from 'routes'
import { Media } from 'service/media'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SideNavItem from './SideNavItem'
import SideNavUpdate from './SideNavUpdate'
import NavBottomAccount from './NavBottomAccount'
import PeopleIcon from '@mui/icons-material/People'
import HoursRemaining from 'components/help/HoursRemaining'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import VideocamIcon from '@mui/icons-material/Videocam'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrg } from 'service/orgsApi'
import { getUsage } from 'service'
import { auth } from 'service/api'
import { OrgUserRole, SalesforceApp } from 'client'
import { SideNavSalesforce, SideNavRedtail, SideNavWealthbox, SideNavRingCentral } from './SideNavItems'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import RegionBadge from 'components/auth/RegionBadge'
import { gSx } from 'styles/Theme'
import { getSalesforceSettings } from 'service/integrations/salesforceApi'
import { getRedtailSettings } from 'service/integrations/redtailApi'
import { getWealthboxSettings } from 'service/integrations/wealthboxApi'
import { getRingCentralProfile } from 'service/integrations/ringCentralApi'
import WhatsNew from './WhatsNew'

interface Props {
  drawerWidth: number
  setOpen: (open: boolean) => void
}

export default function NavContents({ drawerWidth, setOpen }: Props) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const fbUser = auth.currentUser
  const [showSettings, setShowSettings] = useState(false)

  useEffect(() => {
    const p = window.location.href
    const show = p.includes(Path.Settings)
      || p.includes(Path.RecorderSettings)
      || p.includes(Path.Organization)
      || p.includes(Path.Billing)
      || p.includes(Path.ZoomSettings)
      || p.includes(Path.RedtailSettings)
      || p.includes(Path.WealthboxSettings)
      || p.includes(Path.Integrations)

    setShowSettings(show)
  }, [window.location.href])

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
  })

  const { data: usage } = useQuery({
    queryKey: ['getUsage'], queryFn: getUsage,
  })

  const { data: salesforce } = useQuery({
    queryKey: ['getSalesforceSettings'], queryFn: getSalesforceSettings,
    enabled: queryClient.getQueryData(['getSalesforceSettings']) === undefined,
  })
  const isSalesforce = !!salesforce?.sf_uid
  const salesforceApp = salesforce?.app ?? SalesforceApp.SALESFORCE

  const { data: redtail } = useQuery({
    queryKey: ['getRedtailSettings'], queryFn: getRedtailSettings,
    enabled: queryClient.getQueryData(['getRedtailSettings']) === undefined,
  })
  const isRedtail = !!redtail?.user_id

  const { data: wb } = useQuery({
    queryKey: ['getWealthboxSettings'], queryFn: getWealthboxSettings,
    enabled: queryClient.getQueryData(['getWealthboxSettings']) === undefined,
  })
  const isWealthbox = !!wb?.user_id

  const isAnyCrmsConnected = isSalesforce || isRedtail || isWealthbox

  const { data: ringcentral } = useQuery({
    queryKey: ['getRingCentralProfile'], queryFn: getRingCentralProfile,
  })
  const isRingcentral = ringcentral?.id

  useEffect(() => {
    const isInactive = org?.org_user_list
      .some(u => u.uid == fbUser?.uid && u.org_user_role == OrgUserRole.INACTIVE)

    if (isInactive)
      navigate(Path.BlockInactive)
  })

  const sxContainer: SxProps = {
    overflowX: 'hidden',
    width: `${drawerWidth}px`,
    paddingTop: '28px',
  }

  const sxFooter: SxProps = {
    width: `${drawerWidth}px`,
    position: 'fixed',
    bottom: 0,
  }

  return (
    <Box sx={sxContainer}>

      <Box sx={gSx.CenterVertical}>
        <ButtonBase
          aria-label='finmate AI home'
          onClick={() => navigate(Path.Home)}
        >
          <Box
            component='img'
            aria-label='Finmate AI logo'
            alt='Finmate AI logo'
            sx={sxLogo}
            src={Media.logo100}
          />
          <Typography variant='h4' sx={sxTitle}>
            FinMate AI
          </Typography>
        </ButtonBase>
      </Box>

      <Box sx={gSx.CenterVertical}>
        <RegionBadge />
      </Box>

      <List
        sx={{ width: '100%', maxWidth: 360 }}
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        {
          !showSettings ? (
            <>
              <SideNavItem
                icon={<DashboardIcon />}
                path={Path.Home}
                title={'Dashboard'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<CalendarMonthIcon />}
                path={Path.Calendar}
                title={'Calendar'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<PeopleIcon />}
                path={Path.Clients}
                title={'Contacts'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<SettingsIcon />}
                path={Path.Settings}
                title={'Settings'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<CloudSyncIcon />}
                path={Path.Integrations}
                title={'Integrations'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<AutoAwesomeIcon />}
                path={Path.Templates}
                title={'Templates'}
                setOpen={setOpen}
              />

              <Divider sx={{ margin: 2 }} />

              <SideNavUpdate />

              <WhatsNew />
            </>
          ) : (
            <>
              <ButtonBase
                onClick={() => {
                  setShowSettings(false)
                  navigate(Path.Home)
                }}
                sx={{ padding: 1.6 }}
              >
                <ArrowBackIcon sx={{ width: 18 }} />
                <Box sx={{ paddingX: 1 }} />
                <Typography variant='h5'>
                  Settings
                </Typography>
              </ButtonBase>

              <Divider sx={{ marginX: 2, marginY: 1.5 }} />

              <SideNavItem
                icon={<PersonOutlineIcon />}
                path={Path.Settings}
                title={'General'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<VideocamIcon />}
                path={Path.RecorderSettings}
                title={'Recorder'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<CorporateFareIcon />}
                path={Path.Organization}
                title={'Organization'}
                setOpen={setOpen}
              />

              <SideNavItem
                icon={<AccountBalanceIcon />}
                path={Path.Billing}
                title={'Billing'}
                setOpen={setOpen}
              />

              <Divider sx={{ marginX: 2, marginY: 1.5 }} />
              <Typography sx={{ paddingTop: 2, paddingX: 2, paddingBottom: 1, fontWeight: 'normal' }}>Integrations</Typography>

              <SideNavItem
                icon={<CloudSyncIcon />}
                path={Path.Integrations}
                title={'Overview'}
                setOpen={setOpen}
              />

              {!isAnyCrmsConnected &&
                <>
                  <SideNavRedtail setOpen={setOpen} />
                  <SideNavWealthbox setOpen={setOpen} />
                  <SideNavSalesforce setOpen={setOpen} />
                </>
              }
              {isRedtail && <SideNavRedtail setOpen={setOpen} />}
              {isWealthbox && <SideNavWealthbox setOpen={setOpen} />}
              {isSalesforce &&
                <SideNavSalesforce setOpen={setOpen} app={salesforceApp} />
              }

              {isRingcentral && <SideNavRingCentral setOpen={setOpen} />}

              <SideNavItem
                icon={<Box
                  component="img"
                  sx={sxIcon}
                  src={Media.zoomLogo}
                  alt="Zoom"
                />}
                path={Path.ZoomSettings}
                title={'Zoom'}
                setOpen={setOpen}
              />

              {/* Scrolling past bottom contents */}
              <Box sx={{ paddingY: '160px' }} />
            </>
          )

        }

      </List>

      <Box sx={sxFooter}>
        <HoursRemaining org={org} usage={usage} />
        {/* <SideNavUpdate /> */}
        <Divider sx={{ marginX: 2 }} />
        <NavBottomAccount drawerWidth={drawerWidth} org={org} usage={usage} />
      </Box>
    </Box >
  )
}

const sxLogo: SxProps = {
  height: 30,
  width: 22,
  resizeMode: 'contain',
  marginRight: '8px',
}

const sxTitle: SxProps = {
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'primary.main'
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}