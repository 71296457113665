import React from 'react'
import { Box, Paper, SxProps } from '@mui/material'
import { gSx } from 'styles/Theme'

interface CustomContainerProps {
  children: React.ReactNode;
}

const ContainerMedium: React.FC<CustomContainerProps> = ({ children }) => {
  return (
    <Box sx={gSx.CenterVertical}>
      <Box sx={{ maxWidth: 700 }}>
        {children}
      </Box>
    </Box>
  )
}

export default ContainerMedium

export const sxContainerMedium: SxProps = {
  margin: 'auto',
  width: '100%',
  maxWidth: '700px',
  padding: '15px',
}
