import React, { ChangeEvent, useEffect, useState } from 'react'
import { Typography, Box, Container, Button, SxProps, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import 'firebase/auth'
import { Path } from '../routes'
import { logInfo } from '../log'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SkeletonRows } from 'components/Skeletons'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import { CalendarEvent } from 'client'
import CalendarEventSimple from 'components/calendar/CalendarEventSimple'
import { getCalendarEvents } from 'service/calendarApi'
import { gSx } from 'styles/Theme'
import ShowWealthboxRecurringToggle from 'components/calendar/ShowWealthboxRecurringToggle'
import CalendarConnectOptions from 'components/calendar/CalendarConnectOptions'
import CalendarMenu from 'components/calendar/CalendarMenu'

export default function Calendar() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    logInfo('Calendar Screen')
  }, [])

  const { data, isLoading } = useQuery({
    queryKey: ['getCalendarEvents'],
    queryFn: getCalendarEvents,
  })
  const anyCalConnected =
    (data?.is_recall_cal_signed_in || data?.is_redtail_signed_in || data?.is_wealthbox_signed_in) ??
    false

  useEffect(() => {
    if (data && !data.is_recall_cal_signed_in) {
      navigate(Path.CalendarAuth)
    }
  }, [data])

  return (
    <Container>
      <CalendarMenu
        calEvents={data}
        title={<Typography variant='h1'>Calendar Events</Typography>}
      />

      <ShowWealthboxRecurringToggle cal={data} />

      {data?.is_wealthbox_signed_in && (
        <Typography variant='caption'>
          Wealthbox recurring events may have trouble showing up in this calendar
        </Typography>
      )}

      <Divider sx={{ marginY: 1 }} />

      {isLoading ? (
        <SkeletonRows count={10} height={100} />
      ) : anyCalConnected ? (
        data?.events?.length === 0 ? (
          <Typography sx={{ mt: 5 }}>No upcoming events</Typography>
        ) : (
          data?.events?.map((event) => {
            const date = new Date(event.start_time ?? '')
            const readable = date.toLocaleDateString('en-US', {
              weekday: 'short',
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })
            return (
              <Box key={event.id}>
                {ShowDateIfEarliest(date, data.events) && (
                  <Box sx={sxDate}>
                    <CalendarTodayIcon />
                    <Box sx={{ paddingX: '1vh' }} />
                    <Typography>{readable}</Typography>
                  </Box>
                )}
                <CalendarEventSimple calEvent={event} />
              </Box>
            )
          })
        )
      ) : (
        <CalendarConnectOptions />
      )}
    </Container>
  )
}

function ShowDateIfEarliest(date: Date, all: CalendarEvent[]) {
  const meetingsOftheDay = all.filter(
    (m) => new Date(m.start_time ?? '').toLocaleDateString() === date.toLocaleDateString()
  )
  for (const m of meetingsOftheDay) {
    if (new Date(m.start_time ?? '') < date) return false
  }
  return true
}

const sxDate: SxProps = {
  paddingX: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: 1
}
