import React, { useState } from "react"
import { Box, Button, Card, Typography, Link, SxProps, Container, CardContent, CardActions, CardHeader, Chip } from "@mui/material"
import { OverridableStringUnion } from '@mui/types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { FinmateSubscription, OrgBase, ProductName } from "../../client"
import { useTheme } from '@mui/material/styles'
import { useStore } from "hooks/useStore"
import CheckoutDialog from "./CheckoutDialog"
import { gColor, gSx } from "styles/Theme"
import Grid from "@mui/material/Unstable_Grid2"
import CheckIcon from '@mui/icons-material/Check'

interface Props {
  sub?: FinmateSubscription
  isAdmin: boolean
  org?: OrgBase
}

export default function PricingPlansNew({ isAdmin, org, sub }: Props) {
  const theme = useTheme()
  const [openCart, setOpenCart] = useState(false)

  function handleButtonClick() {
    setOpenCart(true)
  }

  const litePrice = sub?.lite?.price ? `$${(sub?.lite?.price?.unit_amount ?? 0) / 100}` : undefined

  const starterDescription = [
    "20 hours / seat / month", // TODO: overage $4 / hour 
    "AI Meeting Summary",
    "Meeting Analytics",
    "Automated Recording Rules via Calendar",
  ]
  const starterPrice = sub?.starter?.price?.unit_amount ? `$${sub?.starter?.price?.unit_amount / 100}` : undefined

  const standardDescription = [
    "40 hours / seat / month", // TODO: overage $3 / hour 
    "AI Meeting Summary",
    "Meeting Analytics",
    "Automated Recording Rules via Calendar",
  ]
  const standardPrice = sub?.standard?.price?.unit_amount ? `$${sub?.standard?.price?.unit_amount / 100}` : undefined

  const enterpriseDescription = [
    "Everything in Standard", // TODO: overage $3 / hour 
    "Customized Data Retention Policies",
    "Custom Templates",
    "Premium support",
  ]

  return (
    <>
      <Typography variant='h1' sx={{ paddingY: '2vh' }} >Plans</Typography>

      <Grid container spacing={2}>

        <Grid xs={12} md={6} lg={3} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header
              title='Starter' price={starterPrice}
              backgroundColor={gColor.blue400}
            />

            <CardContent>
              {starterDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions>
              {isAdmin ? (
                <Button variant={'outlined'} fullWidth
                  onClick={handleButtonClick} aria-label='Select Starter'>
                  Select Plan
                </Button>
              ) : (
                <Typography color={gColor.green500}>
                  Contact your administrator to purchase seats
                </Typography>
              )}
            </CardActions>

            <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
              <Typography variant="caption">
                For starter users, surcharge rates over 20 hours a month may apply.
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid xs={12} md={6} lg={3} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header
              title='Standard' price={standardPrice} isMostPopular
              backgroundColor={gColor.blue600}
            />

            <CardContent>
              {standardDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions sx={{ paddingX: 2 }}>
              {isAdmin ? (
                <Button variant={'outlined'} fullWidth
                  onClick={handleButtonClick}>
                  Select Plan
                </Button>
              ) : (
                <Typography color={gColor.green500}>
                  Contact your administrator to purchase seats
                </Typography>
              )}
            </CardActions>

            <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
              <Typography variant="caption">
                For standard users, surcharge rates over 40 hours a month may apply.
              </Typography>
            </Box>
          </Card>
        </Grid>

        <Grid xs={12} md={6} lg={3} sx={sxGrid}>
          <Card sx={sxCard}>
            <Header title='Enterprise' backgroundColor={gColor.blue800} />

            <CardContent>
              {enterpriseDescription.map((line: string) => (
                <Box key={line} display="flex" alignItems="center" my={1}>
                  <CheckIcon sx={{ color: '#2bc379' }} />
                  <Typography variant="body2" sx={{ ml: 1 }}>{line}</Typography>
                </Box>
              ))}
            </CardContent>

            <CardActions sx={{ paddingX: 2 }}>
              {isAdmin ? (
                <Button variant={'outlined'} fullWidth
                  onClick={() => window.location.href = 'mailto:sales@finmate.ai'}>
                  Contact Sales
                </Button>
              ) : (
                <Typography color={gColor.green500}>
                  Contact your administrator to purchase seats
                </Typography>
              )}
            </CardActions>

          </Card>
        </Grid>

        <Grid xs={12} md={6} lg={3} sx={sxGrid}>
          <Card sx={sxCard} >
            <Header title='Lite' backgroundColor={gColor.blue300} price={litePrice} />

            <CardContent>
              <Typography variant='body1' sx={{ paddingBottom: 1 }}>
                For assistants.
              </Typography>
              <Typography variant='body1'>
                Perfect for those who want to manage shared meetings. Lite users can edit CRMs, operate analytics, send followup emails, etc.
              </Typography>
            </CardContent>

            <CardActions sx={{ paddingX: 2, paddingTop: 2 }}>
              {isAdmin && (
                <Button variant='outlined' fullWidth
                  onClick={handleButtonClick} aria-label={ProductName.LITE}>
                  Select Plan
                </Button>
              )}
            </CardActions>

          </Card>

        </Grid>

        <Grid xs={12} md={12} >

          <Card>
            <CardHeader title="Small Business Discounts Available" titleTypographyProps={{ align: "center" }} />

            <CardContent>
              <Typography variant='body1' textAlign="center">
                We&apos;re committed to making our tool accessible to startups, small RIAs, and Advisors.
              </Typography>
              <Typography variant='body1' textAlign="center">
                Contact us, and we&apos;d be happy to explore how we can make our tool available to all firms.
              </Typography>
              <Box sx={{ padding: '1vh' }} />
              <Box sx={sxButtonWrapper}>
                <Link href={`mailto:sales@finmate.ai`} color="inherit">
                  <Button variant="outlined" aria-label='Contact Sales'>
                    {`Contact Sales`}
                  </Button>
                </Link>
              </Box>
            </CardContent>

          </Card>

        </Grid>
      </Grid>

      {org &&
        <CheckoutDialog
          org={org}
          open={openCart}
          setOpen={setOpenCart}
        />
      }

    </>
  )
}

interface HeaderProps {
  title: string
  price?: string
  backgroundColor: string
  isMostPopular?: boolean
}

function Header({ title, price, backgroundColor, isMostPopular }: HeaderProps) {
  return (
    <Box sx={{
      backgroundColor: backgroundColor,
      paddingX: 4,
      paddingTop: 0.5,
      height: '80px',
    }}>

      {isMostPopular
        ? <Box sx={{ backgroundColor: 'red', borderRadius: 10, display: 'inline-block', paddingX: 0.5 }}>
          <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 9 }}>Most Popular</Typography>
        </Box>
        : <Box sx={{ padding: 1.35 }} />
      }

      <Box sx={gSx.RowBetween}>
        <Typography variant="h5" color={'white'} sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography variant="h5" color={'white'} sx={{ fontWeight: 'bold' }}>{price}</Typography>
      </Box>

      {price && <Box sx={gSx.RowEnd}>
        <Typography variant='caption' color='white'> seat / month</Typography>
      </Box>
      }

    </Box>
  )
}

const sxGrid: SxProps = { display: 'flex' }

const sxCard: SxProps = { margin: 0, padding: 0, flexGrow: 1 }

const sxButtonWrapper: SxProps = {
  display: 'flex',
  justifyContent: 'center',
}
