import React, { useEffect, useState } from "react"
import { OrgUserRole, StripeCustomer } from "../../client"
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, Typography } from "@mui/material"
import { useQuery } from '@tanstack/react-query'
import { getOrg } from "../../service/orgsApi"
import { updateCustomer } from "../../service/payApi"
import { logInfo } from "../../log"
import { LoadingButton } from "@mui/lab"

interface Props {
  customer?: StripeCustomer
  setModal: (show: boolean) => void
  showModal: boolean
  refetch: () => void
}

export default function ChooseBillingContact({ customer, setModal, showModal, refetch }: Props) {
  const [email, setEmail] = useState(customer?.email ?? '')
  const [busy, setBusy] = useState(false)
  const [err, setErr] = useState<string | undefined>()

  useEffect(() => {
    if (showModal)
      logInfo('Edit ChooseBillingContact')
  }, [showModal])

  const { data, isLoading } = useQuery({
    queryKey: ['getOrg'],
    queryFn: getOrg
  })

  const activeOrgUsers = data?.org_user_list.filter(u => u.org_user_role != OrgUserRole.INACTIVE)

  async function onConfirm() {
    setErr(undefined)
    setBusy(true)
    const res = await updateCustomer(email)
    if (res instanceof Error)
      setErr(res.message)
    else {
      setModal(false)
      await refetch()
    }
    setBusy(false)
  }

  return (
    <Dialog open={showModal} onClose={() => setModal(false)}>
      <DialogTitle>
        Select a Billing Contact
      </DialogTitle>

      <DialogContent>
        <Typography>
          As an admin of your organization, you can change the billing contact.
        </Typography>

        <Box sx={{ padding: '1vh' }} />
        {
          isLoading ? (
            <Skeleton variant="rectangular" width={'100%'} height={100} />
          ) : (
            <>
              <FormControl fullWidth>
                <InputLabel id="select-label">Admin</InputLabel>
                <Select
                  labelId="select-label"
                  id="demo-simple-select"
                  value={email}
                  label="Admins"
                  onChange={(e) => setEmail(e.target.value)}
                >
                  {
                    activeOrgUsers?.map(user => {
                      const nameEmail = user?.name ? `${user?.name}, ${user?.email}` : `${user?.email}`

                      return (
                        <MenuItem
                          key={user.uid}
                          value={user.email}
                        >
                          {`${nameEmail}, (${user.org_user_role})`}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>

            </>
          )
        }
        {err && <Typography color={'tomato'} sx={{ paddingTop: 1 }}>{err}</Typography>}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          onClick={() => setModal(false)}
          disabled={busy}
          loading={busy}
        >
          Cancel
        </LoadingButton>

        <LoadingButton
          variant="contained"
          onClick={onConfirm}
          disabled={busy}
          loading={busy}
        >
          Confirm
        </LoadingButton>
      </DialogActions>

    </Dialog >
  )
}
