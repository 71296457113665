import React, { useState } from 'react'
import { Typography, Skeleton, CardHeader } from '@mui/material'
import { FinmateSubscription } from 'client'
import Grid from '@mui/material/Unstable_Grid2'
import { isAdminRole } from 'service/orgsApi'

interface Props {
  sub?: FinmateSubscription
  isLoading: boolean
}
export default function SeatsPurchased({ sub, isLoading }: Props) {

  function Numbers() {
    if (isLoading || !sub)
      return <Skeleton variant="rectangular" width={'100%'} height={40} />

    return (
      <>
        <Typography>{sub.standard?.quantity ?? 0}</Typography>
        <Typography>{sub.starter?.quantity ?? 0}</Typography>
        <Typography>{sub.lite?.quantity ?? 0}</Typography>
      </>
    )
  }

  return (
    <>
      <CardHeader title="Seats Purchased" />

      <Grid container>
        <Grid xs={11}>
          <Typography>Standard Plan</Typography>
          <Typography>Starter Plan</Typography>
          <Typography>Lite Plan</Typography>
        </Grid>

        <Grid xs={1}>
          <Numbers />
        </Grid>
      </Grid>
    </>
  )
}