import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, SxProps, TextField, Typography, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { logInfo } from '../../log'
import { gColor, gSx } from 'styles/Theme'
import { auth } from 'service/api'
import { updateProfile } from 'firebase/auth'
import { updateNameInDB } from 'service'

interface Props {
  open: boolean
  onClose: () => void
}

export default function ProfileEditDialog({ open, onClose }: Props) {
  const fbUser = auth.currentUser
  const [name, setName] = useState(fbUser?.displayName)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    if (open) {
      logInfo('ProfileEditDialog Open')
    }
  }, [open])

  async function onChangeName() {
    setBusy(true)
    if (fbUser) {
      const nameTrimmed = name?.trim() ?? ''
      await updateProfile(fbUser, { displayName: nameTrimmed })
      updateNameInDB(nameTrimmed)
      logInfo('ProfileEditDialog Change', { name })
    }
    setBusy(false)
    onClose()
  }

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>
        Update Your Display Name
      </DialogTitle>

      <DialogContent>
        <TextField
          sx={{ marginY: 2 }}
          label='Display Name'
          aria-label='Display Name'
          onChange={(t) => setName(t.target.value)}
          variant="outlined"
          value={name}
          disabled={busy}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          aria-label="cancel"
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={onChangeName}
          aria-label="Update"
          disabled={busy}
        >
          Update
        </Button>
      </DialogActions>

    </Dialog >
  )
}
