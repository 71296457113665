import React, { useState, useEffect } from 'react'
import {
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardHeader,
  CardContent,
  Box,
  IconButton,
  Stack,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material'
import { Api } from '../../service/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { ModuleIdentifier, SubPromptOptionToneOfVoiceType, SubPromptOptionFormatType, SubPromptOptionLevelOfDetailType } from '../../client'
import { LoadingButton } from '@mui/lab'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SampleOutputDialog, SampleOutputHighlightOptions } from './SampleOutputDialog'
import { getEventTranscript, getUnchangedSpeakers } from '../../service/eventApi'
import { logInfo } from '../../log'
import { CustomizerLoading } from '../Skeletons'
import SpeakerNameConfirmDialog from 'components/event/SpeakerNameConfirmDialog'
import SubCard from 'components/SubCard'

interface Props {
  eventId: string
  disabled: boolean
  isRerun?: boolean
  onBuildEmail?: () => void
}

export default function FollowupEmailCustomizer({ eventId, disabled, isRerun, onBuildEmail }: Props) {
  const queryClient = useQueryClient()
  const [editedCustomFollowupEmail, setEditedCustomFollowupEmail] = useState<ModuleIdentifier | null>(null)
  const [busy, setBusy] = useState(false)
  const [openSampleOutputDialog, setOpenSampleOutputDialog] = useState(false)
  const [subpromptTemplateId, setSubpromptTemplateId] = useState("")
  const [sectionTitle, setSectionTitle] = useState("")
  const [sampleHighlightOptions, SetSampleHighlightOptions] = useState<SampleOutputHighlightOptions>({})
  const [buildConfirmDialog, setBuildConfirmDialog] = useState(false)
  const [unchangedSpeakers, setUnchangedSpeakers] = useState("")

  const { data: followupEmailModule, isLoading } = useQuery({
    queryKey: ['getCustomFollowupMail'],
    queryFn: runQuery,
  })

  async function runQuery() {
    const api = await Api()
    return await api.customizations.getCustomFollowupEmail()
  }

  const { data: transcript } = useQuery({
    queryKey: ['getEventTranscript', eventId],
    queryFn: async () => await getEventTranscript(eventId, false),
    enabled: queryClient.getQueryData(['getEventTranscript', eventId]) === undefined,
  })

  useEffect(() => {
    if (followupEmailModule) {
      setEditedCustomFollowupEmail(followupEmailModule)
    }
  }, [followupEmailModule])

  const handleToneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editedCustomFollowupEmail?.subprompt_affix_list && editedCustomFollowupEmail?.subprompt_affix_list.length > 0) {
      setEditedCustomFollowupEmail((prev) => ({
        ...prev,
        subprompt_affix_list: prev?.subprompt_affix_list?.map((subPrompt, index) => {
          if (index === 0) {
            return {
              ...subPrompt,
              options: {
                ...subPrompt.options,
                tone_of_voice: event.target.value as SubPromptOptionToneOfVoiceType
              }
            }
          }
          return subPrompt
        })
      }))
    }
  }

  const handleFormatChange = (e: SelectChangeEvent<SubPromptOptionFormatType>, template_id: string) => {
    setEditedCustomFollowupEmail((prev) => ({
      ...prev,
      subprompt_list: prev?.subprompt_list?.map((subPrompt, index) => {
        if (template_id === subPrompt.template_id) {
          let limit = 1
          if (e.target.value == SubPromptOptionFormatType.LIST) {
            limit = 5 // backend may update the limit based on other config
          }
          return {
            ...subPrompt,
            options: {
              ...subPrompt.options,
              format_type: e.target.value as SubPromptOptionFormatType,
              format_limit: limit
            }
          }
        }
        return subPrompt
      })
    }))
  }

  const handleLevelOfDetailChange = (e: SelectChangeEvent<SubPromptOptionLevelOfDetailType>, template_id: string) => {
    setEditedCustomFollowupEmail((prev) => ({
      ...prev,
      subprompt_list: prev?.subprompt_list?.map((subPrompt, index) => {
        if (template_id === subPrompt.template_id) {
          return {
            ...subPrompt,
            options: {
              ...subPrompt.options,
              level_of_detail: e.target.value as SubPromptOptionLevelOfDetailType,
            }
          }
        }
        return subPrompt
      })
    }))
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    if (editedCustomFollowupEmail?.subprompt_list) {
      const newSubPromptitems = Object.assign([], editedCustomFollowupEmail?.subprompt_list)
      const [reorderedItem] = newSubPromptitems.splice(result.source.index, 1)
      newSubPromptitems.splice(result.destination.index, 0, reorderedItem)
      setEditedCustomFollowupEmail((prev) => ({
        ...prev,
        subprompt_list: newSubPromptitems
      }))
    }
  }

  const buildEmailCheck = async () => {
    setBusy(true)

    const unchangedSpeakersList = getUnchangedSpeakers(transcript)
    if (unchangedSpeakersList.length == 0) {
      // all the speakers are updated
      await buildEmail()
    } else {
      // show dialog
      const unchangedSpeakersString = unchangedSpeakersList.join(",  ")
      setUnchangedSpeakers(unchangedSpeakersString)
      setBuildConfirmDialog(true)
    }
    setBusy(false)
  }

  const buildEmail = async () => {
    // Logic to build the follow-up email based on selected options
    if (editedCustomFollowupEmail) {
      const api = await Api()
      const res = await api.customizations.processCustomFollowupEmail(eventId, isRerun, editedCustomFollowupEmail)
      queryClient.invalidateQueries({ queryKey: ['getEventFollowupEmail', eventId] })
      queryClient.invalidateQueries({ queryKey: ['getEventSummaryStatus', eventId] })
      if (onBuildEmail) {
        onBuildEmail()
      }
      queryClient.invalidateQueries({ queryKey: ['getEvent', eventId] })

      logInfo('Building follow-up email', { eventId, res })

    }
  }

  const handleCloseConfirmDialog = async (confirm: boolean) => {
    setBusy(true)
    if (confirm) {
      await buildEmail()
    }
    setBuildConfirmDialog(false)
    setBusy(false)
  }

  const openSampleOutput = (submodule_template_id: string, sectionTitle: string, highlightOptions: SampleOutputHighlightOptions) => {
    setSubpromptTemplateId(submodule_template_id)
    setSectionTitle(sectionTitle)
    SetSampleHighlightOptions(highlightOptions)
    setOpenSampleOutputDialog(true)
  }

  const closeSampleOutput = () => {
    setOpenSampleOutputDialog(false)
  }

  if (isLoading || editedCustomFollowupEmail == null) {
    return <CustomizerLoading />
  }

  return (
    <Card >
      <CardHeader
        title="Customize Meeting Follow-up Email"
        subheader="Effortlessly tailor and build your custom follow-up email.  Your selections will be saved for future meetings."
        action={
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={buildEmailCheck}
            loading={busy}
            disabled={disabled}
          >
            Build Follow-up Email
          </LoadingButton>
        }
      />
      <SubCard sx={{ padding: 0, marginBottom: 1 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Tone of Voice
          </Typography>
          <Box sx={{ paddingLeft: 1, paddingRight: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
              <RadioGroup
                row
                value={editedCustomFollowupEmail.subprompt_affix_list ? editedCustomFollowupEmail.subprompt_affix_list[0].options?.tone_of_voice : SubPromptOptionToneOfVoiceType.FORMAL}
                onChange={handleToneChange}
              >
                {Object.values(SubPromptOptionToneOfVoiceType).map((type) => (
                  <FormControlLabel value={type} control={<Radio />} label={type} key={type} />
                ))}
              </RadioGroup>
              <Tooltip title={`View Sample Results`} sx={{ marginLeft: 'auto' }}>
                <IconButton aria-label='info'
                  onClick={() => {
                    if (editedCustomFollowupEmail && editedCustomFollowupEmail.subprompt_affix_list && editedCustomFollowupEmail.subprompt_affix_list[0] && editedCustomFollowupEmail.subprompt_affix_list[0].template_id) {
                      openSampleOutput(editedCustomFollowupEmail.subprompt_affix_list[0].template_id, "Tone of Voice", { ToneOfVoice: true } as SampleOutputHighlightOptions)
                    }
                  }
                  }>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </FormControl>
          </Box>
        </CardContent>
      </SubCard>

      <SubCard sx={{ padding: 0, marginBottom: 1 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Email Sections
          </Typography>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {editedCustomFollowupEmail?.subprompt_list?.map((section, index) => (
                    <Draggable key={section.template_id} draggableId={section.template_id ?? String(index)} index={index}>
                      {(provided) => (
                        <Accordion
                          defaultExpanded
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          sx={{ marginBottom: 1 }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            {...provided.dragHandleProps}
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Tooltip title="Drag to Reorder">
                              <IconButton {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                              </IconButton>
                            </Tooltip>

                            <Box sx={{ width: '33%', display: 'flex', alignItems: 'center', marginLeft: 1 }}>
                              <Typography>
                                {section.template_ui_title}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack direction="row" spacing={2}>
                              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <InputLabel shrink sx={{ backgroundColor: '#f5f5f5', padding: '3px' }}>Format</InputLabel>
                                <Select
                                  value={section.options?.format_type}
                                  onChange={(e) => {
                                    handleFormatChange(e, section.template_id ?? "")
                                  }}
                                >
                                  {Object.values(SubPromptOptionFormatType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth>
                                <InputLabel shrink sx={{ backgroundColor: '#f5f5f5', padding: '3px' }}>Level of Detail</InputLabel>
                                <Select
                                  value={section.options?.level_of_detail}
                                  onChange={(e) => {
                                    handleLevelOfDetailChange(e, section.template_id ?? "")
                                  }}
                                >
                                  {Object.values(SubPromptOptionLevelOfDetailType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {type}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Tooltip title={`View Sample Results`}>
                                <IconButton aria-label='info'
                                  onClick={() => {
                                    if (section && section.template_id && section.template_ui_title) {
                                      openSampleOutput(section.template_id, "Section: " + section.template_ui_title, { LevelOfDetail: true, Format: true })
                                    }
                                  }
                                  }>
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </CardContent>
      </SubCard>
      {
        subpromptTemplateId && subpromptTemplateId.length > 0 && (
          <SampleOutputDialog sectionTitle={sectionTitle} open={openSampleOutputDialog} onClose={closeSampleOutput} subpromptTemplateId={subpromptTemplateId} highlightOptions={sampleHighlightOptions} />
        )
      }

      <SpeakerNameConfirmDialog
        openDialog={buildConfirmDialog}
        closeDialog={handleCloseConfirmDialog}
        busy={busy}
        unchangedSpeakers={unchangedSpeakers}
      />

    </Card >
  )
}
