/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarEvent } from '../models/CalendarEvent';
import type { CalendarEvents } from '../models/CalendarEvents';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CalendarService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get Recall Auth Token
     * @returns string Successful Response
     * @throws ApiError
     */
    public getRecallAuthToken(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/calendar/recall_token',
        });
    }

    /**
     * Get Calendar Events
     * @returns CalendarEvents Successful Response
     * @throws ApiError
     */
    public getCalendarEvents(): CancelablePromise<CalendarEvents> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendar/',
        });
    }

    /**
     * Update Calendar Meeting
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateCalendarMeeting(
        requestBody: CalendarEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/calendar/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
