import React from 'react'
import { OrgBase, StripeDiscount } from '../../client'
import { Card, Typography } from '@mui/material'
import { gColor } from '../../styles/Theme'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getCustomer } from 'service/payApi'
import { getOrg } from 'service/orgsApi'
import SubCard from 'components/SubCard'

export default function Discount() {
  const queryClient = useQueryClient()

  const { data: org } = useQuery({
    queryKey: ['getOrg'], queryFn: getOrg,
    enabled: queryClient.getQueryData(['getOrg']) === undefined,
  })

  const { data: customer } = useQuery({
    queryKey: ['getCustomer'], queryFn: getCustomer,
    enabled: queryClient.getQueryData(['getCustomer']) === undefined,
  })

  const coupon = customer?.discount?.coupon

  if (!org || !coupon)
    return null

  return (
    <SubCard>
      <Typography>Exclusive Discount for {org.org_name} </Typography>
      {coupon?.amount_off && (
        <Typography>
          {`Amount off: $${coupon.amount_off / 100}`}
        </Typography>
      )}

      {coupon?.percent_off && (
        <Typography>
          {`Percent off: ${coupon.percent_off}%`}
        </Typography>
      )}

      {coupon?.duration_in_months && (
        <Typography>
          {`Num of Months: ${coupon.duration_in_months}`}
        </Typography>
      )}
    </SubCard>
  )
}