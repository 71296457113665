import React, { useRef, useEffect, useState } from 'react'
import { Api, auth, config } from '../../service/api'
import { Box, SxProps, Card, Typography, CircularProgress, LinearProgress } from '@mui/material'
import { observer } from 'mobx-react-lite'
import ReactPlayer from 'react-player'
import { useQuery } from '@tanstack/react-query'
import { useStore } from '../../hooks/useStore'
import { retryDelayExpBackOffUptoSevenSecs, retryStopOnlyExpired } from '../../hooks/hookHelpers'
import { gSx } from 'styles/Theme'

interface QueryError {
  message: string;
  description: string;
  statusCode: string | number;
  status: string | number;
}

interface Props {
  eventId: string
  width: number
  isPublic?: boolean
}

export default observer(VideoCard)
function VideoCard({ eventId, isPublic, width }: Props) {
  const playerRef = useRef(null)
  const store = useStore()
  const seekSeconds = store.User.getSeekSeconds()
  const [videoUrl, setVideoUrl] = useState('')

  const { data, error, isLoading } = useQuery({
    queryKey: ['getEventVideo' + eventId],
    refetchOnWindowFocus: false,
    queryFn: runQuery,
    retry: retryStopOnlyExpired,
    retryDelay: retryDelayExpBackOffUptoSevenSecs, // retry max 7 secs
  })

  async function runQuery() {

    const api = await Api()
    const res = isPublic
      ? await api.public.getPublicEventVideo(eventId)
      : await api.events.getEventVideo(eventId)
    return res
  }

  useEffect(() => {
    if (data && data.bucket_filename && data.bucket_filename != videoUrl) {
      setVideoUrl(data.bucket_filename)
    }
  }, [data?.bucket_filename])

  const isAudio = () => {
    const audioExtensions = ['.mp3?', '.wav?', '.ogg?', '.aac?']
    return audioExtensions.some((ext) => videoUrl.includes(ext))
  }

  const sxCard: SxProps = {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }

  if (error) {
    const queryError = error as QueryError
    if (queryError.status === 410) {
      return (
        <Card sx={sxCard}>
          <Box sx={gSx.CenterVertical}>
            <Typography variant="h6" color="grey">Video Expired</Typography>
          </Box>
        </Card>
      )
    }
  }
  const height = isAudio()
    ? 50
    : Math.min((width * 9) / 16, 300)

  if (playerRef.current) {
    // @ts-expect-error seekto never
    playerRef.current.seekTo(seekSeconds, 'seconds')
  }

  if (isLoading || !data) {
    return (
      <Card sx={sxCard}>
        <Box sx={gSx.CenterVertical}>
          <Typography>Video Processing</Typography>
          <Box sx={{ width: '100%', paddingY: '1vh' }}>
            <LinearProgress />
          </Box>
        </Box>
      </Card>
    )
  }

  function onVideoProgress(event: any) {
    store.User.setTranscriptSeekSeconds(event.playedSeconds)
  }
  return (
    <Card sx={{ borderRadius: '5px', padding: 0 }}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        controls
        light={data.thumbnail_filename}
        width={`${width}px`}
        height={`${height}px`}
        volume={0.5}
        onProgress={onVideoProgress}
      />
    </Card >
  )
}
